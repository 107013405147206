<template>
  <ul
    v-if="socialLinks"
    class="social-links">
    <a
      v-for="socialLink in socialLinks"
      :id="'social-' + socialLink.name"
      :key="socialLink.url"
      :href="socialLink.url"
      target="_blank"
    >
      <li>
        <img
          :alt="socialLink.name"
          :src="socialLink.icon"
          width="40"
          height="40" >
        <span class="social-number">{{ socialLink.name }}</span>
        <!-- // // <label class="social-label">Labe El</label> -->
      </li>
    </a>
  </ul>
</template>
<script>
export default {
  name: 'FooterSocialLinks',
  computed: {
    socialLinks() {
      if (
        this.$store.state.settings &&
        this.$store.state.settings.socialLinks &&
        this.$store.state.settings.socialLinks.length
      ) {
        return this.$store.state.settings.socialLinks
      }
      return null
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/resources.sass';
.social-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: calc(100% - 2rem);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  a {
    flex: 1;
    padding: 0 0.5em;
    background: #444444;
    &:hover {
      transition-duration: 0.5s;
      transition-property: all;
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 400px) {
    flex-wrap: wrap;
    // a {
    // flex: auto;
    // width: 100%;
    // }
  }

  li {
    // width: 285px;
    // width: auto;
    flex: 1;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mobile {
      // width: 40vw;
    }
  }

  li .fa {
    font-size: 21px;
    line-height: 24px;
    margin-top: 9px;
    @include mobile {
      font-size: 19px;
    }
  }

  li .social-number {
    font-size: 18px;
    line-height: 21px;
    display: block;
    margin-top: 8px;
    text-transform: capitalize;

    @include mobile {
      font-size: 16px;
      margin-top: 8px;
      line-height: 13px;
    }
  }

  li .social-label {
    font-weight: normal;
    display: block;
    font-size: 11px;
    line-height: 12px;

    @include mobile {
      font-size: 10px;
    }
  }
}
</style>
