/**
 * Doc
 * https://wiki.planeta.guru/doku.php?id=pgj_api_server_integracion_cliente#inicio_de_sesion
 */

export const state = () => ({
  uCode: null
})

export const mutations = {
  uCode(state, code) {
    state.uCode = code
  }
}

export const actions = {
  async getUCode({ commit, dispatch, rootState }) {
    const loading = this.$router.app.$loading
    const http = this.$router.app.$http
    // If we have a u_code use it
    if (this.$cookies.get('pg_ucode')) {
      let uCodeCookie = this.$cookies.get('pg_ucode')
      let uCode = uCodeCookie.body.data.object.u_code
      commit('uCode', uCode)
      return uCodeCookie
    }
    // Generate a new u_code
    loading.start()
    const params = {
      grant_type: 'authorization_code',
      client_id: rootState.env.CLIENT_ID,
      code_verifier: this.$cookies.get('pg_cv_oath'),
      access_token: rootState.token.accessToken
    }
    const parsedParams = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&')
    var requestLink =
      rootState.env.CLIENT_URL + '/rest/api/account/token?' + parsedParams
    const requestFallback = response => {
      dispatch('log', { type: 'info', request: response }, { root: true })
      const data = response.data.data
      const uCode = data.object.u_code
      this.$cookies.set('pg_ucode', response, {
        path: '/',
        maxAge: JSON.parse(process.env.globalConfigs).UCODE_MAXAGE
      })
      commit('uCode', uCode)
      loading.finish()
      return response
    }
    const requestCatch = error => {
      loading.fail()
      dispatch('log', { type: 'error', request: error }, { root: true })
      if (error.status === 401 || error.status === 400) {
        dispatch('removeCookies', null, { root: true })
      }
      return null
    }
    return await http
      .post(requestLink, '', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json, text/plain, */*'
        }
      })
      .then(requestFallback, requestCatch)
  },
  async buidAccountLink({ state, dispatch, rootState }, payload) {
    var ucode = await dispatch('getUCode')
    if (!ucode) {
      return
    }
    let params = {
      redirect_uri: encodeURIComponent(window.location.href),
      product_id: rootState.env.PRODUCT_ID,
      code_challenge: this.$cookies.get('pg_cc_oath'),
      code_challenge_method: 'S256',
      u_code: state.uCode
    }
    if (payload.params) {
      params = {
        ...params,
        ...payload.params
      }
    }
    const parsedParams = Object.keys(params)
      .map(key => key + '=' + params[key])
      .join('&')
    return `${payload.url}?${parsedParams}`
  },
  async goTo({ dispatch }, payload) {
    const url = await dispatch('buidAccountLink', payload)
    if (url) {
      window.location.href = url
    } else {
      dispatch(
        'log',
        {
          type: 'error',
          message: 'No se pudo generar el link. Error al obtener el u_code.',
          notify: true
        },
        { root: true }
      )
    }
  },
  async goToSubscriptions({ dispatch, rootState }, payload) {
    let params = {}
    if (payload && payload.unsubscribe) {
      params.origin = 'portal'
    }
    await dispatch('goTo', {
      url: `${rootState.env.PROFILE_URL}/account/subscriptions`,
      params: params
    })
  },
  async goToProfile({ dispatch, rootState }) {
    await dispatch('goTo', {
      url: `${rootState.env.PROFILE_URL}/account/`
    })
  },
  async goToSecurity({ dispatch, rootState }) {
    await dispatch('goTo', {
      url: `${rootState.env.PROFILE_URL}/account/security/edit/username`
    })
  },
  async goToEditPassword({ dispatch, rootState }) {
    if (rootState.settings.disableEditPassword) {
      alert('Edit password option is disabled for this store.')
      return
    }
    await dispatch('goTo', {
      url: `${rootState.env.PROFILE_URL}/account/security/edit/password`
    })
  },
  async goToTransactions({ dispatch, rootState }) {
    await dispatch('goTo', {
      url: `${rootState.env.PROFILE_URL}/account/transactions`
    })
  }
}
