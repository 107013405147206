<template>
  <button
    type="button"
    class="button-hamburger"
    @click.prevent="$emit('click')">
    <span class="icon-bar"/>
    <span class="icon-bar"/>
    <span class="icon-bar"/>
  </button>
</template>
<script>
export default {
  name: 'HamburguerIcon'
}
</script>
<style lang="scss">
.button-hamburger {
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
  // Bars
  .icon-bar {
    display: block;
    width: 2rem;
    height: 4px;
    border-radius: 1px;
  }
  .icon-bar + .icon-bar {
    margin-top: 4px;
  }
}
</style>
