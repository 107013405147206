export default function(context) {
  let href
  let hrefShort
  if (process.browser) {
    href = window.location.origin + process.env.baseUrl + context.route.fullPath
    hrefShort =
      window.location.origin + process.env.baseUrl + context.route.path

    context.store.dispatch('log', {
      type: 'info',
      message: `[setHref]: init href to "${href}" hrefShort to "${hrefShort}"`
    })
  } else {
    let protocol
    try {
      context.store.dispatch('log', {
        type: 'info',
        message: `[setHref]: context.req.headers.referer "
        ${context.req.headers.referer}"`
      })

      protocol = context.req.headers.referer
      protocol = protocol.match('h\\w*://')[0]

      context.store.dispatch('log', {
        type: 'info',
        message: `[setHref]: protocolo obtenido desde url 1 "${protocol}"`
      })
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        protocol = 'http://'

        context.store.dispatch('log', {
          type: 'info',
          message: `[setHref]: protocolo obtenido desde url 2 "${protocol}"`
        })
      } else {
        if (
          context.store.state.providersHosts.find(host => {
            return (
              host.host == context.req.headers.host && host.protocol === 'http'
            )
          })
        ) {
          protocol = 'http://'
        } else {
          protocol = 'https://'
        }
      }
    }

    context.store.dispatch('log', {
      type: 'info',
      message: `[setHref]: protocol href "${protocol}"`
    })

    href =
      protocol +
      context.req.headers.host +
      process.env.baseUrl +
      context.route.fullPath
    hrefShort =
      protocol +
      context.req.headers.host +
      process.env.baseUrl +
      context.route.path
  }
  // Find host
  let host
  if (context.req) {
    host = context.req.headers.host
  } else {
    try {
      host = window.location.host
    } catch (error) {
      context.store.dispatch('log', {
        type: 'warn',
        message:
          '[setHref] No host name could be found. Add "payload.req" for SSR requests'
      })
    }
  }
  // console.log(href)
  context.store.dispatch('log', {
    type: 'info',
    message: `setHref: href to "${href}" hrefShort to "${hrefShort}"`
  })
  context.store.commit('set', {
    href,
    hrefShort,
    host
  })
}
