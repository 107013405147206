export default function(context) {
  let vue = context.app

  /**
   * Clean Query String
   * @query {obj} Full Raw Query
   * @exclude {array} Keys you want to exclude from Query
   */
  const cleanQuery = (query, exclude) => {
    let newUrl = context.store.state.hrefShort
    if (query) {
      let queryCount = 0
      Object.keys(query).forEach(function(key) {
        if (
          key &&
          (!exclude ||
            (exclude && !exclude.find(excludeKey => key == excludeKey)))
        ) {
          if (queryCount > 0) {
            newUrl += `&${key}=${query[key]}`
          } else {
            newUrl += `?${key}=${query[key]}`
          }
          queryCount++
        }
      })
    }
    return newUrl
  }

  /**
   * Debug Notification
   */
  if (context.route.query.notify) {
    context.app.$cookies.set('pg_notify', context.route.query.notify, {
      path: '/'
    })
    context.store.commit('setDebugConfig', {
      notify: context.route.query.notify
    })
  } else if (context.app.$cookies.get('pg_notify')) {
    context.store.commit('setDebugConfig', {
      notify: context.app.$cookies.get('pg_notify')
    })
  }

  /**
   * Check if we have entel Params
   */
  let hasEntelParams = false
  // if (context.route.query.suscrod) {
  if (context.route.query.msfw) {
    /*if (context.req.method === 'POST') {*/
    hasEntelParams = true

    context.store.dispatch('log', {
      type: 'info',
      message: '[getParams] hasEntelParams - true ' + context.req.method
    })
    /*} else {
      context.store.dispatch('log', {
        type: 'warn',
        message:
          '[getParams] hasEntelParams - Invalid request method: ' +
          context.req.method
      })
    }*/
  }

  /**
   * Look for Query Params
   *
   * These params are used by oAuth, Rug
   * and for Internationalization
   *
   * If you need to send this param to oAuth
   * make sure to add this param to `oauthParams`
   * in `signin` action on `src/store/actions.js`
   * */
  if (
    // Internationalization
    context.route.query.locale ||
    // oAuth
    context.route.query.prvdr ||
    context.route.query.userID ||
    context.route.query.service ||
    context.route.query.mdsp ||
    context.route.query.provider ||
    context.route.query.msfw ||
    context.route.query.suscrod ||
    // Rug
    context.route.query.s ||
    context.route.query.utm_source ||
    context.route.query.utm_medium ||
    context.route.query.utm_campaign ||
    context.route.query.campaignParams
  ) {
    let query = context.route.query
    // Merge with params on Cookies
    if (context.app.$cookies.get('pg_params')) {
      query = {
        ...context.app.$cookies.get('pg_params'),
        ...query
      }
    }
    //console.log(context.app.$cookies.get('pg_params').utm_source)
    // Send params to store and cookies
    context.store.commit('set', { params: query })
    context.app.$cookies.set('pg_params', query, {
      path: '/'
    })
    // Is it safe to clean the URL?
    if (
      !(
        context.route.query.state == 'oauth' ||
        context.route.query.state == 'logged' ||
        context.route.query.code ||
        context.route.query.token ||
        hasEntelParams
      )
    ) {
      context.store.dispatch('log', {
        type: 'info',
        message:
          '[getParams] Cleaning query after finding Params - hasEntelParams: ' +
          hasEntelParams
      })
      context.redirect(cleanQuery())
    }
  } else if (context.app.$cookies.get('pg_params')) {
    // Send params saved on cookies to Store
    context.store.commit('set', {
      params: context.app.$cookies.get('pg_params')
    })
  }

  /**
   * Check for UDI Params
   */
  if (context.route.query.token) {
    let maxAge = context.route.query['expiresIn']
      ? context.route.query['expiresIn']
      : 300
    vue.$cookies.set('pg_udi_tkn', context.route.query.token, {
      path: '/',
      maxAge
    })
    context.store.commit('set', {
      udiToken: context.route.query.token
    })
    const curentDate = new Date()
    const created = curentDate.getTime()
    let expiration = created + 10000
    if (context.route.query['expiresIn']) {
      expiration = created + context.route.query['expiresIn'] * 1000
    }
    vue.$cookies.set('pg_udi_tkn_exp', expiration, {
      path: '/',
      maxAge
    })
    vue.$cookies.set('pg_ani', context.route.query.aniFound, {
      path: '/',
      maxAge
    })
    context.store.dispatch('log', {
      type: 'info',
      message: '[getParams] Cleaning query after finding Token'
    })
    context.redirect(
      cleanQuery(context.route.query, ['token', 'expiresIn', 'aniFound'])
    )
  }

  /**
   * Check for Token Params
   */
  if (context.route.query.code) {
    vue.$cookies.set('pg_cd', context.route.query.code, { path: '/' })
    context.store.dispatch('log', {
      type: 'info',
      message: '[getParams] Cleaning query after finding Code'
    })
    context.redirect(cleanQuery(context.route.query, ['code', 'state']))
  }

  /**
   * Redirect to oauth?
   */
  if (context.route.query.state == 'oauth' || hasEntelParams) {
    if (!vue.$cookies.get('pg_tkn')) {
      // If there is no token redirect to oAuth
      context.store.dispatch('log', {
        type: 'info',
        message: 'User redirected to oAuth due to getParams middleware'
      })
      context.store.dispatch('signin', {
        vue,
        redirect: context.redirect,
        redirectUri: cleanQuery(context.route.query, ['state'])
      })
    } else if (vue.$cookies.get('pg_tkn')) {
      // If there is a token clean the URL. We don't need to login
      context.redirect(cleanQuery(context.route.query, ['state']))
    }
  }

  /**
   * Set language
   */
  if (context.app.$cookies.get('pg_params')) {
    context.store.commit('set', {
      language: context.app.$cookies.get('pg_params').locale
    })
  } else if (context.route.query.locale) {
    context.store.commit('set', { language: context.route.query.locale })
  } else if (
    context.store.state.profile &&
    context.store.state.profile.language
  ) {
    // TODO: Ups there is no profile loaded yet
    context.store.commit('set', {
      language: context.store.state.profile.language
    })
  }
}
