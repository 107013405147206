<template>
  <div
    id="app-virtual">
    <template v-if="loaded && !$store.state.loading">
      <no-ssr>
        <Rug v-if="$store.state.isRugVisible && $store.getters.hasRug"/>
      </no-ssr>
      <Popup/>
      <WelcomePopup v-if="$store.state.settings"/>
      <Navbar
        v-if="$store.state.settings && !$store.state.isFullScreenActive
          && !($route.name =='detail-slug' && $store.state.product =='html5')
        && !($route.name =='download-slug' && $store.state.product =='html5')"
        ref="navbar"
        :is-claro="idClaro"/>
      <nuxt @loading="(val)=>{viewLoading = val}" />
      <foot
        v-if="$store.state.settings && !$store.state.isFullScreenActive
          && ($route.name != 'login' && $route.name != 'register')
          && !($route.name =='detail-slug' && $store.state.product =='html5')
        && !($route.name =='download-slug' && $store.state.product =='html5')"/>
    </template>
    <loading
      v-else
      ref="loading"/>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import Footer from '@/components/Footer'
import Popup from '@/components/Popup'
import Navbar from '@/components/Navbar'
import WelcomePopup from '@/components/WelcomePopup'
import Rug from '@/components/Rug'

export default {
  name: 'App',
  components: {
    Loading,
    Popup,
    Navbar,
    WelcomePopup,
    foot: Footer,
    Rug
  },

  data() {
    return {
      coding: '',
      viewLoading: false,
      countryCode: null,
      storeBrowserNavigationDetails: false,
      faviconUrl: this.$store.state.favicon,
      hostName: null
    }
  },
  computed: {
    loaded() {
      const token = this.$store.state.token
      const user = this.$store.state.profile

      if (!this.viewLoading && !this.$store.state.loading) {
        if ((!token && !this.$cookies.get('pg_cd')) || (token && user)) {
          return true
        }
      }
      return true
    },
    userData() {
      const user = this.$store.state.profile
      const ani = user !== null ? user.phones[0].msisdn : null
      const userId = user !== null ? user.userId : null

      return { userId, ani }
    },
    idClaro() {
      return this.hostName === 'claro'
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.faviconUrl
        }
      ]
    }
  },
  watch: {
    $route(to, from) {
      if (to !== from || this.$route.fullPath == '/') {
        this.storeNavigationsDetail()
      }
    }
  },
  mounted() {
    this.setHostName()
    this.displayLoadErrors()
    this.displayMetas()
    this.displaySubscriptions()
    this.startSubscribeReminder()
    this.getHostName()
    this.storeNavigationsDetail()
    this.getLocalStorageCode()
    this.getCoutryCode()
  },
  methods: {
    getHostName() {
      const host = window.location.hostname
      this.storeBrowserNavigationDetails = host.search('planeta') >= 0
    },
    setHostName() {
      const host = window.location.hostname
      if (host.search('planeta') >= 0) {
        this.hostName = 'planeta'
        this.storeBrowserNavigationDetails = true
      }
      if (host.search('claro') >= 0) {
        this.hostName = 'claro'
        this.storeBrowserNavigationDetails = false
      }
    },
    getLocalStorageCode() {
      const code = localStorage.getItem('countryCode')
      this.countryCode = code
    },
    setLocalStorageCode(code) {
      localStorage.setItem('countryCode', code)
    },
    getCoutryCode() {
      if (this.countryCode == null && this.storeBrowserNavigationDetails) {
        fetch('https://api.ipregistry.co/?key=tryout')
          .then(response => response.json())
          .then(data => {
            const code = data.location.country.code
              ? data.location.country.code
              : null
            this.setLocalStorageCode(code)
            this.countryCode = code
          })
      }
    },
    getBrowserDetails() {
      let data = {
        userAgent: 'no detectado',
        version: 'no detectado',
        platform: 'no detectado'
      }
      if (navigator.userAgentData !== undefined) {
        const brands = navigator.userAgentData.brands
        const invalidData = [
          'Not=A?Brand',
          'Not;A=Brand',
          'Chromium',
          'Not.A/Brand'
        ]
        brands.forEach(item => {
          if (!invalidData.includes(item.brand)) {
            data.userAgent = navigator.userAgent
            data.version = item.version
          }
        })
        data.platform = navigator.userAgentData.platform
      } else {
        data = {
          userAgent: navigator.userAgent,
          version: navigator.appVersion,
          platform: navigator.platform
        }
      }
      return data
    },
    getPath() {
      return this.$route.fullPath
    },
    async storeNavigationsDetail() {
      if (this.countryCode == 'PE' && this.storeBrowserNavigationDetails) {
        const browserData = this.getBrowserDetails()
        const path = this.getPath()
        const userData = this.userData
        const response = await this.$store.dispatch(
          'backend/storeUserTracing',
          {
            browserData,
            path,
            ...userData
          }
        )
      }
    },
    displayLoadErrors() {
      const errors = this.$cookies.get('pg_load_errors')
      if (errors) {
        this.$nextTick(() => {
          errors.forEach(error => {
            this.$notify({
              group: 'debug',
              type: 'error',
              title: error.title,
              text: error.message
            })
          })
          this.$cookies.remove('pg_load_errors')
        })
      }
    },
    displayMetas() {
      const settings = this.$store.state.settings
      const metas = settings ? settings['_meta'] : {}
      let formatedMetas = this.formatLogObject(metas)
      this.$nextTick(() => {
        this.$store.dispatch('log', {
          type: 'debug',
          message: '[ Setttings Meta ] <br>' + formatedMetas
        })
      })
    },
    formatLogObject(metas) {
      let formatedObject = ''
      Object.keys(metas).forEach(metaKey => {
        let metavalue = metas[metaKey]
        if (typeof metavalue == 'object') {
          metavalue = JSON.stringify(metavalue)
        }
        formatedObject += metaKey + ' : ' + metavalue + ' <br>'
      })
      return formatedObject
    },
    displaySubscriptions() {
      let subscriptions
      try {
        subscriptions = this.$store.state.subscriptions
      } catch (error) {}
      if (!subscriptions) {
        return
      }
      const formatedSubscriptions = this.formatLogObject(subscriptions)
      this.$nextTick(() => {
        this.$store.dispatch('log', {
          type: 'debug',
          message: '[ Setttings Subscriptions ] <br>' + formatedSubscriptions
        })
      })
    },
    startSubscribeReminder() {
      const subscribeReminder = this.$store.state.settings
        ? this.$store.state.settings.subscribeReminder
        : null
      if (
        subscribeReminder &&
        subscribeReminder.frequency &&
        !this.$store.state.isSubscribeReminderRunning &&
        process.browser
      ) {
        console.debug('[startSubscribeReminder] Started Reminders')
        this.$store.commit('set', { isSubscribeReminderRunning: true })
        setInterval(() => {
          if (
            !this.$store.state.settings['_meta'].subscribed &&
            !this.$store.state.isRugVisible &&
            this.$route.name !== 'download-slug' &&
            !this.$store.state.popup.name
          ) {
            this.$store.commit('popup', {
              name: 'subscribeReminder'
            })
            console.debug('[startSubscribeReminder] Opened the popup')
          } else {
            console.debug('[startSubscribeReminder] Tried to open the popup')
          }
        }, subscribeReminder.frequency * 1000)
      }
    }
  }
}
</script>
