/* eslint-disable */

export default ({ app, store }) => {
  /*
  ** Only run on client-side and only in production mode
  */
  if (process.env.NODE_ENV !== 'production') return

  /**
   * Only run if there is a  Provider Enviroment
   */
  const providerEnv = store.getters.providerHost;
  if(!providerEnv || !providerEnv.googleTagManager) return
  /**
   * Only run if there is an ID
   */
  if(!providerEnv.googleTagManager.id) {
    console.error('[tagManager] No id found on googleTagManager');
    return
  }

  /**
   * Set options
   *
   * Dump:
   * const options = {
   *   id: 'GTM-PKTKXK9',
   *   layer: 'dataLayer',
   *   pageTracking: true,
   * }
   */

  const options = providerEnv.googleTagManager
  options.layer = options.layer || 'dataLayer'

  /*
  ** Include Google Analytics Script
  */
 if (options.id.includes('UA-')) {
    window[options.layer] = window[options.layer] || [];
    var script = document.createElement('script');
    script.onload = function () {
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', options.id);
      };
      script.src = 'https://www.googletagmanager.com/gtag/js?id=' + options.id;
      script.async = true;
      document.head.appendChild(script);
 }else{
   const googleScript = function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
     {'gtm.start': new Date().getTime(),event:'gtm.js'}
     );var f=d.getElementsByTagName(s)[0],
     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
   }
   googleScript(window,document,'script',options.layer,options.id);
 }

  //if (ga)
  //  ga('require', 'displayfeatures')

  /*
  ** Every time the route changes (fired on initialization too)
  */
  if (options.pageTracking) {
    // Every time the route changes (fired on initialization too)
    app.router.afterEach((to, from) => {
      setTimeout(() => {
        window[options.layer].push(to.gtm || {
          event: 'nuxtRoute',
          pageType: 'PageView',
          pageUrl: to.fullPath,
          routeName: to.name
        })
      }, 0)
    })
  }
}
