<template>
  <div
    id="nav-container">
    <!--
      <div
      :class="{active:isMenuActive }"
      class="overlay"
      @click="hamburguerClick"/>
    -->
    <!--
    <nav
      id="cbp-spmenu-s2"
      :class="{'cbp-spmenu-open': isMenuActive}"
      class="cbp-spmenu cbp-spmenu-vertical cbp-spmenu-left">
      <div
        v-if="!logged"
        class="user-links">
        <a @click="$store.dispatch('signin', {vue: $router.app.$root})">{{ $store.getters.messages('login') }}</a>
      </div>
      <div
        v-else-if="$store.state.profile"
        class="user-links">
        <nuxt-link
          to="profile"
          class="user">
          <img
            :src="$store.state.profile.avatarURL"
            alt="avatar"
            class="avatar">
          {{ $store.state.profile.nickname }}
          <span>
            <template v-if="$store.getters.availableDownloads === 1">
              {{ $store.getters.messages('downloadAvailable') }}: {{ $store.getters.availableDownloads }}
            </template>
            <template v-else-if="$store.getters.unlimitedDownloads">
              {{ $store.getters.messages('unlimitedDownloads') }}
            </template>
            <template v-else>
              {{ $store.getters.messages('availableDownloads') }}: {{ $store.getters.availableDownloads }}
            </template>
          </span>
        </nuxt-link>
        <a
          v-if="mainActiveSubscriptions !== 'mx.claro.tiaxa' && mainActiveSubscriptions !== 'ec.claro.tiaxa'"
          @click="goTo({ name: 'profile' })">
          {{ $store.getters.messages('myProfile') }}
        </a>
        <a @click="goTo({ name: 'MyGames' })">
          {{ $store.getters.messages('myGames') }}
        </a>
        <a @click="goTo({ name: 'favorites' })">{{ $store.getters.messages('myFavorites') }}</a>
        <a
          v-if="$store.state.subscriptions.mainSubscriptionSlug && mainActiveSubscriptions !== 'mx.claro.tiaxa' && mainActiveSubscriptions !== 'ec.claro.tiaxa'"
          @click="sendToSubscriptions">{{ $store.getters.messages('mySubscription') }}</a>
        <a
          v-if="$store.state.subscriptions.mainSubscriptionSlug"
          @click="unsubscribe">{{ $store.getters.messages('cancelSubscription') }}</a>
        <a @click="$store.commit('logOut', $root)">{{ $store.getters.messages('logout') }}</a>
      </div>
      <ul
        v-if="$store.state.categories"
        class="categories">
        <li
          v-for="(medal) in $store.state.categories"
          :key="medal.slug">
          <a @click="goTo({ name: 'category-id', params: { id: medal.slug }})">
            {{ medal.title }}
          </a>
        </li>
      </ul>
    </nav>
    -->
    <Menu
      :isMenuActive="isMenuActive"
      :showPlanAnual="isClaro"
      hamburger
      @hamburger-click="hamburguerClick"/>

    <nav class="main-nav">
      <ul role="menu">
        <li>
          <HamburguerIcon @click="hamburguerClick"/>
        </li>

        <li id="nav-logo">
          <router-link to="/">
            <img
              :src="logoUrl"
              alt="Home Link">
          </router-link>
        </li>

        <li
          v-if="$store.state.categories"
          class="categories">
          <button class="button-transparent">
            {{ $store.getters.messages('searchCategory') }}
            <i class="fa fa-chevron-down"/>
          </button>
          <span class="list">
            <ul>
              <li
                v-for="(medal) in $store.state.categories"
                :key="medal.slug">
                <nuxt-link :to="{ name: 'category-id', params: { id: medal.slug }}">
                  <span>
                    {{ medal.title }}
                  </span>
                </nuxt-link>
              </li>
            </ul>
          </span>
        </li>

        <li
          :class="{'search-vr': this.$store.state.product == 'clarovr'}"
          class="search">
          <div class="search-bar">
            <i class="fa fa-search"/>
            <input
              :placeholder="$store.getters.messages('search')"
              v-model="searchQuery"
              name="search"
              autocomplete="off">
            <span
              :class="{active: searchBarTooltipActive.visible}"
              class="search-bar-tooltip">
              {{ $store.getters.messages('searchQueryTooShort') }}
            </span>
          </div>
        </li>

        <li
          v-if="this.$store.state.isMobileScreen && this.$store.state.product == 'clarovr'"
          class="cart">
          <a
            href="#"
            @click.prevent="$store.dispatch('handleSubscriptionRemember', () => { window.location = link })">
            <img
              :src="require(`@/assets/default-images/shopping-cart.svg`)">
          </a>
        </li>

        <li
          v-if="!this.$store.state.isMobileScreen && this.$store.state.product == 'clarovr'"
          class="cart">
          <button
            type="button"
            class="button"
            aria-label="Close modal"
            @click.prevent="$store.dispatch('handleSubscriptionRemember', () => { window.location = link })">
            Suscribirse
          </button>
        </li>

        <li
          v-if="!$store.state.settings.disableLoginButton"
          id="nav-right-info"
          class="">
          <template v-if="!logged">
            <a
              class="button"
              @click="$store.dispatch('signin', {vue: $router.app.$root})">{{ $store.getters.messages('login') }}</a>
          </template>
          <template v-else-if="$store.state.profile">
            <a
              class="button"
              @click="$store.commit('logOut', $root)">{{ $store.getters.messages('logout') }}</a>
          </template>
        </li>
      </ul>
    </nav>

  </div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import HamburguerIcon from '@/components/HamburguerIcon.vue'
export default {
  name: 'NavBar',
  head() {
    return {
      bodyAttrs: {
        class: this.bodyClass
      },
      htmlAttrs: {
        class: this.$store.state.isFullScreenActive ? 'fullscreen' : ''
      },
      meta: this.srIdTransaction
        ? [
            {
              hid: 'google-id',
              name: 'google-id',
              id: 'meta-google-id',
              'google-id': this.srIdTransaction
            }
          ]
        : []
    }
  },
  components: {
    Menu,
    HamburguerIcon
  },
  props: {
    isClaro: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: null,
      searchBarTooltipActive: {
        visible: false,
        firsTime: true
      }
      //// bodyClassList: [
      //// 'cbp-spmenu-push',
      ///// 'tpi-state-usersession-off',
      ///// 'tpi-state-neutro-off',
      ///// 'tpi-state-scrolled-off',
      ///// 'tpi-state-responsive-off'
      //// ]
    }
  },
  computed: {
    logged() {
      return this.$store.state.profile ? true : false
    },
    resetSearchQuery() {
      const query = this.$store.state.resetSearchQuery
      if (query) {
        this.searchQuery = null
      }
      return query
    },
    isMenuActive() {
      //// if (this.$store.state.isMenuActive) {
      ////   this.modifyBodyClass([
      ////     { action: 'add', class: 'cbp-spmenu-push-toright' }
      ////   ])
      //// } else {
      ////   this.modifyBodyClass([
      ////     { action: 'remove', class: 'cbp-spmenu-push-toright' }
      ////   ])
      //// }

      return this.$store.state.isMenuActive
    },
    bodyClass() {
      var classList = [
        'product-' + this.$store.state.product,
        this.isMenuActive ? 'menu-active' : 'menu-inactive'
      ]
      return classList.join(' ')
    },
    srIdTransaction() {
      if (this.$store.state.srIdTransaction) {
        return this.$store.state.srIdTransaction
      } else if (
        this.$store.state.profile &&
        this.$store.state.profile.srIdTransaction
      ) {
        return this.$store.state.profile.srIdTransaction
      }
    },
    logoUrl() {
      if (this.$store.state.settings && this.$store.state.settings.logoUrl) {
        return this.$store.state.settings.logoUrl
      }
      return require('../assets/logo.png')
    }
  },
  watch: {
    searchQuery(val) {
      this.searchBarTooltipActive.visible = false
      const minLength = 2
      if (
        (val && val.length >= minLength) ||
        val.toLowerCase() == 'vr' ||
        val.toLowerCase() == 'ar'
      ) {
        if (!this.$router.name === 'search') {
          this.$router.push({ name: 'search', query: { q: this.searchQuery } })
        } else {
          this.$router.replace({
            name: 'search',
            query: { q: this.searchQuery }
          })
        }
      } else if (
        val &&
        val.length !== 0 &&
        val.toLowerCase() !== 'vr' &&
        val.toLowerCase() == 'ar'
      ) {
        var toggleTooltip = setTimeout(() => {
          if (this.searchQuery.length < minLength) {
            this.searchBarTooltipActive.visible = true
            setTimeout(() => {
              if (val.length == this.searchQuery.length) {
                this.searchBarTooltipActive.visible = false
              }
            }, 3000)
          }
        }, 1700)
      } else if (val.length === 0) {
        this.$router.push({ name: 'index' })
      }
    },
    $route() {
      this.hamburguerClick('close')
      //// this.modifyBodyClass([
      ////   { action: 'add', class: 'tpi-state-scrolled-off' },
      ////   { action: 'remove', class: 'tpi-state-scrolled-on' }
      //// ])
    }
  },
  methods: {
    hamburguerClick(status) {
      this.$store.dispatch('log', {
        type: 'debug',
        message: `[hamburguerClick] ${status}`
      })
      if (process.browser) {
        if (status == 'close') {
          this.$store.commit('set', { isMenuActive: false })
        } else if (status == 'open') {
          this.$store.commit('set', { isMenuActive: true })
        } else {
          this.$store.commit('set', {
            isMenuActive: !this.$store.state.isMenuActive
          })
        }
      }
    },
    sendToSubscriptions() {
      this.$store.commit('set', { loading: true })
      this.$store.dispatch('pgserver/goToSubscriptions')
    },
    unsubscribe() {
      this.$store.commit('popup', {
        name: 'unsubscribe'
      })
    },
    getCurrentBodyClass() {
      if (process.browser) {
        return [...document.getElementsByTagName('BODY')[0].classList]
      }
      return this.bodyClassList
    },
    modifyBodyClass(classList) {
      const currentBodyClass = this.getCurrentBodyClass()
      let bodyClassList = currentBodyClass
      classList.forEach(classElement => {
        if (classElement.action == 'remove') {
          bodyClassList = bodyClassList.filter(filterClassName => {
            return filterClassName != classElement.class
          })
        } else {
          bodyClassList = [...bodyClassList, ...[classElement.class]]
        }
      })
      this.bodyClassList = bodyClassList
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

$navbar-categories-breakpoint: 1000px;

.mobile-hidden {
  @include mobile {
    display: none;
  }
}

#nav-container {
  width: 100%;
  height: 5.6rem;
  @include mobile {
    height: 7.9rem;
  }
  .overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: $navbar-transition;
    @media (max-width: #{$navbar-categories-breakpoint}) {
      &.active {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  a:hover {
    text-decoration: none;
    cursor: pointer;
  }
  .user-links {
    a {
      font-size: 0.95em;
      padding-top: 1.25em;
      padding-bottom: 1.25em;
      font-weight: 700;
      @include s1 {
        padding-top: 0.75em;
        padding-bottom: 0.75em;
      }
    }
  }
  ul.categories {
    list-style: none;
    padding: 0;
    margin: 0.75em 0;
    a {
      border: 0;
      font-size: 0.95em;
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      padding-right: 2em;
      position: relative;
      @include s1 {
        padding-top: 0.5em;
        padding-bottom: 0.5em;
      }
      &:after {
        position: absolute;
        right: 1em;
        top: calc(50% - 0.5em);
        display: block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\f054';
      }
    }
    @media (min-width: #{$navbar-categories-breakpoint}) {
      display: none;
    }
  }

  .main-nav {
    position: fixed;
    z-index: 3;
    width: 100%;
    height: inherit;

    .button {
      border-radius: 3px;
      padding: 0.35em 1.5em;
      cursor: pointer;
      display: inline-block;
      &:hover {
        text-decoration: none;
      }
    }

    @include s1 {
      height: 94px;
    }

    ul[role='menu'] {
      @include bootstrap-width;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      list-style: none;
      margin: 0 auto;
      left: 0;
      right: 0;
      padding: 10px;
      height: 94px;
      @include mobile {
        #nav-logo {
          order: 0;
        }
        .search {
          width: 100%;
          order: -1;
        }
        .search-vr {
          width: 45%;
          order: -1;
        }
      }
    }
    @include mobile {
      height: auto;
      ul[role='menu'] {
        height: auto;
        #nav-logo {
          order: 0;
        }
        .search {
          width: 100%;
          order: 5;
          margin: 1em 0;
        }
        .search-vr {
          width: 45%;
          order: 5;
          margin: 1em 0;
        }
      }
    }
    li.downloads {
      flex: 1 1;
      display: none;
      @include s2 {
        display: block;
      }
    }
    li.categories {
      display: none;
      align-self: stretch;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        height: 90%;
        width: 2px;
        top: 5%;
        left: 0;
      }
      button {
        height: 100%;
        padding-left: 2em;
        padding-right: 2em;
        font-weight: 600;
        i {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
      }
      .list {
        visibility: hidden;
        position: absolute;
        display: block;
        top: 100%;
        padding: 1em 0.75em 1em 1em;
        border-radius: 0 0 0.5em 0.5em;
        &:before {
          content: '';
          position: absolute;
          width: 80%;
          height: 2px;
          top: 0;
          left: 10%;
        }
      }
      ul {
        list-style: none;
        padding: 0 0.4em 0 0;
        margin: 0;
        max-height: 10em;
        @extend %overflow;
        overflow-x: hidden;
      }
      a {
        display: block;
        font-size: 0.95em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        span {
          display: inline-block;
          width: calc(100% - 1em);
        }
        &:after {
          @extend %fa;
          content: '\f054';
          opacity: 0.5;
          right: 0;
        }
      }
      &:hover {
        .list {
          visibility: visible;
        }
      }
      @media (min-width: #{$navbar-categories-breakpoint}) {
        display: block;
      }
    }
    .downloads-mobile {
      opacity: 0.5;
      @include desktop {
        display: none;
      }
    }

    #nav-logo {
      white-space: nowrap;
      justify-self: start;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      img {
        height: 100%;
        @include mobile {
          height: 2rem;
        }
      }
    }
    .search {
      width: 15rem;
      margin-left: auto;
      margin-right: 1.5rem;
    }

    .search-bar {
      position: relative;

      i {
        position: absolute;
        left: 0;
        top: 0;
        padding: 0.5rem 0.5rem;
      }

      input {
        border-radius: 2px;
        border: none;
        padding-left: 2rem;
        width: 100%;
        line-height: 2rem;
      }
      .search-bar-tooltip {
        display: block;
        position: absolute;
        transform: translate(0, 0.5rem);
        padding: 0.25em 0.75em;
        border-radius: 0.25em;
        z-index: 2;
        font-size: 0.9em;
        font-weight: bold;
        transition: opacity $navbar-transition, visibility $navbar-transition;
        opacity: 0;
        visibility: hidden;
        &.active {
          opacity: 1;
          visibility: visible;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: -1rem;
          border: 0.5rem solid transparent;
        }
      }
    }

    #nav-right-info {
      justify-self: flex-end;
      text-align: left;
      @include mobile {
        margin-left: auto;
      }

      #nav-suscribe {
        font-style: italic;

        &::before {
          display: inline-block;
          width: 2px;
          height: 12px;
          background-color: $white;
          content: '';
          margin-right: 15px;
          margin-left: 12px;
        }
      }

      span {
        display: block;
        padding: 0.5em 0;
        font-size: 0.75em;
      }

      button {
        margin: 5px 0 0 -1px;
        border-image: initial;
        border-style: none;
        border-radius: 5px;
        display: block;
        font-weight: bold;
        text-transform: uppercase;
        border-width: 2px;
        padding: 4px 20px;
        text-align: center;
      }
    }
  }
}
.cart {
  order: 6;
  margin-left: 0.5rem;
  @include mobile {
    float: right;
    width: 1em;
  }
}
.cart .fa {
  color: #a40000;
}
.cart .button {
  color: #fff;
  font-weight: 500;
  padding: 0.65em 1.5em !important;
}
//// .cbp-spmenu {
////   @extend %overflow;
////   overflow-x: hidden;
////   a.loading {
////     opacity: 0.75;
////     cursor: progress;
////   }
////   .user {
////     text-align: center;
////     font-weight: 600;
////     img.avatar {
////       display: block;
////       width: 50%;
////       border-radius: 50%;
////       margin: 0 auto 0.5rem;
////       border-radius: 50%;
////     }
////     span {
////       padding: 0;
////       margin: 0 0 0.75em;
////       font-size: 0.75em;
////       font-weight: 400;
////     }
////   }
////   span {
////     display: block;
////     opacity: 0.5;
////     padding: 1.5em 0.75em;
////   }
//// }
//// .cbp-spmenu-right {
////   margin-top: 0;
//// }
</style>
