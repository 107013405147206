<template>
  <div
    v-if="$store.state.settings && $store.state.settings.footerText"
    class="footerDescription">
    <p v-html="$store.state.settings.footerText"/>
  </div>
</template>
<script>
export default {
  name: 'FooterDescription'
}
</script>
<style lang="scss">
.footerDescription {
  padding: 1rem;
  padding-left: 20px;
  margin: 0;
  font-size: 0.9rem;
  text-align: center;
  p {
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0.1em -0.5rem 0.75em;
    padding: 0;
    font-size: 0.8em;
    opacity: 0.75;
  }
  li {
    display: inline-block;
    padding: 0 0.5em;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
