import SHA256 from 'sha256-es'
const srs = require('secure-random-string')
const hex64 = require('hex64')
export default function(context) {
  // Get codes
  const scrypt = srs()
  const scrypt2 = srs()
  const scrypted = scrypt + scrypt2
  const sha256 = SHA256.hash(scrypted)
  const base64 = hex64.encode(sha256)
  // Set a Cod Challenge and Code Verifier if there it none.
  if (
    !context.app.$cookies.get('pg_cc') &&
    !context.app.$cookies.get('pg_cv')
  ) {
    const codeChallenge = base64.replace(new RegExp('=$'), '')
    const codeVerifier = scrypted
    context.app.$cookies.set('pg_cc', codeChallenge, {
      path: '/'
    })
    context.store.commit('set', { codeChallenge })
    context.app.$cookies.set('pg_cv', codeVerifier, { path: '/' })
    context.store.commit('set', { codeVerifier })
  }
  // Set oAuth Code Challenge
  context.app.$cookies.set('pg_cc_oath', base64.replace(new RegExp('=$'), ''), {
    path: '/'
  })
  // Set oAuth Code Verifier
  context.app.$cookies.set('pg_cv_oath', scrypted, { path: '/' })
}
