<template>
  <div class="footer-menu">
    <div class="content fixed">
      <div class="wrapper">
        <ul>
          <li
            v-for="button in buttons"
            :key="button.title">
            <nuxt-link
              v-if="button.to"
              :title="button.title"
              :to="button.to">
              <img
                :alt="button.title"
                :src="require(`@/assets/footer-menu/${button.icon}.svg`)">
            </nuxt-link>
            <a
              v-else
              :title="button.title"
              @click="button.click">
              <img
                :alt="button.title"
                :src="require(`@/assets/footer-menu/${button.icon}.svg`)">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FooterMenu',
  computed: {
    buttons() {
      let buttons = []
      buttons.push({
        title: this.$store.getters.messages('home'),
        icon: 'home',
        to: '/'
      })
      if (this.$store.state.profile) {
        buttons.push({
          title: this.$store.getters.messages('myGames'),
          icon: 'my-games',
          to: '/MyGames'
        })
      } else {
        buttons.push({
          title: this.$store.getters.messages('joinMe'),
          icon: 'subscribe',
          click: this.signIn
        })
      }
      buttons.push({
        title: this.$store.getters.messages('wallet'),
        icon: 'wallet',
        to: '/profile/wallet'
      })
      return buttons
    }
  },
  methods: {
    signIn() {
      this.$store.dispatch('signin', {
        redirectUri: this.$store.getters.cleanQuery(this.$route.query, [
          'state'
        ])
      })
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/resources.sass';
.footer-menu {
  height: $footer-menu-height;
  overflow: hidden;
  .content {
    height: $footer-menu-height;
    position: relative;
    width: 100%;
    z-index: 2;
    text-align: center;
    margin: 0;
    &.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      transition: left $navbar-transition;
    }
  }
  .wrapper {
    max-width: 55rem;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  li {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    border-width: 0;
    border-style: solid;
    border-right-width: 1px;
    &:last-of-type {
      border-right: none;
    }
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
    appearance: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    text-align: center;
    color: inherit;
    padding: 0.5rem;
    cursor: pointer;
  }
  img {
    display: block;
    margin: 0 auto;
  }
}
</style>
