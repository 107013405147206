<template>

  <div class="main-footer">
    <div
      v-if="medals"
      id="categories-background" >
      <no-ssr>
        <carousel
          id="categories"
          :per-page="3"
          :per-page-custom="[[600, 4], [768, 5], [1000, 6],[1200, 8]]"
          :navigation-next-label="navigationNextLabel"
          :navigation-prev-label="navigationPrevLabel"
          :pagination-enabled="false"
          :navigation-enabled="true">
          <slide
            v-for="(medal) in medals"
            :key="medal.slug"
            class="category">
            <nuxt-link :to="{ name: 'category-id', params: { id: medal.slug }}">
              <img
                :src="medal.media && medal.media[0] ? medal.media[0] : ('//via.placeholder.com/150x150/1b2838/?text=' + encodeURI(medal.title))"
                :alt="medal.title">
              <p>{{ medal.title }}</p>
            </nuxt-link>
          </slide>
        </carousel>
        <ul
          slot="placeholder"
          style="visibility: hidden">
          <li
            v-for="(medal) in medals"
            :key="medal.slug">
            <nuxt-link :to="{ name: 'category-id', params: { id: medal.slug }}">
              <p>{{ medal.title }}</p>
            </nuxt-link>
          </li>
        </ul>
      </no-ssr>
    </div>
    <FooterSocialLinks />

    <footer v-if="footerLinks">
      <div id="footer-info">
        <template v-for="(section, key, index) in footerLinks">
          <ul :key="key+'-ul'">
            <li
              v-for="link in section"
              :key="link.title">
              <template v-if="link.action === 'cancelSuscription'">
                <a
                  v-if="$store.state.profile"
                  @click="sendToSubscriptions">{{ link.title }}</a>
              </template>
              <router-link
                v-else-if="link.routeName"
                :to="{ name: 'slug', params: { slug: link.routeName }}">{{ link.title }}</router-link>
              <a
                v-else
                :href="link.url"
                target="_blank">{{ link.title }}</a>
            </li>
          </ul>
          <hr
            v-if="index < Object.keys(footerLinks).length - 1"
            :key="key+'-hr'">
        </template>
      </div>
    </footer>

    <FooterDescription/>
    <FooterFloating/>
    <FooterMenu/>
  </div>
</template>
<script>
import FooterSocialLinks from './FooterSocialLinks.vue'
import FooterFloating from './FooterFloating.vue'
import FooterDescription from './FooterDescription.vue'
import FooterMenu from './FooterMenu.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: {
    FooterSocialLinks,
    FooterFloating,
    FooterDescription,
    FooterMenu,
    Carousel,
    Slide
  },
  data() {
    return {
      health: './Health',
      about: './About',
      newsletter: './Newsletter',
      publish: './Publish',
      contact: './Contact',
      navigationNextLabel: '<i class="fas fa-angle-right fa-lg"></i>',
      navigationPrevLabel: '<i class="fas fa-angle-left fa-lg"></i>'
    }
  },
  computed: {
    footerLinks() {
      if (
        this.$store.state.settings &&
        this.$store.state.settings.footerLinks
      ) {
        let footerLinks = { left: [], right: [] }
        let counter = 0

        Object.entries(this.$store.state.settings.footerLinks).forEach(
          ([slug, link]) => {
            const linksLength = Object.keys(
              this.$store.state.settings.footerLinks
            ).length
            if (counter < linksLength / 2) {
              footerLinks.left.push(link)
            } else {
              footerLinks.right.push(link)
            }
            counter++
          }
        )
        return footerLinks
      }
      return null
    },
    medals() {
      return this.$store.state.medals
    }
  },
  mounted() {
    if (process.browser) {
      this.addExtraScripts()
    }
  },
  methods: {
    sendToSubscriptions() {
      this.$store.commit('popup', {
        name: 'unsubscribe'
      })
    },
    addExtraScripts() {
      if (this.$store.state.settings.extraScripts && process.browser) {
        const vue = this
        const jquerySrc =
          'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.js'
        const extraScripts = document.createElement('script')
        extraScripts.setAttribute('type', 'text/javascript')
        extraScripts.setAttribute('src', jquerySrc)
        console.debug(`[addExtraScripts]: added ${jquerySrc}`)
        document.body.appendChild(extraScripts)
        extraScripts.onload = function() {
          console.debug(`[addExtraScripts]: loaded ${jquerySrc}`)
          vue.$store.state.settings.extraScripts.forEach(script => {
            const extraScripts = document.createElement('script')
            extraScripts.setAttribute('type', 'text/javascript')
            extraScripts.setAttribute('src', script)
            console.debug(`[addExtraScripts]: added ${script}`)
            document.body.appendChild(extraScripts)
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';

$margin-between-sections: 2%;
$margin-between-categories: 0;

#footer-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 1.5% auto 1.5%;
  font-size: 2.5vw;

  @include desktop {
    font-size: 1em;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 $margin-between-sections 0 $margin-between-sections;
  }

  hr {
    align-self: center;
    height: 90px;
    opacity: 0.25;
    width: 1px;
    margin: 0;
    border: 0;
  }
  @media only screen and (max-width: 500px) {
    display: block;
    position: relative;
    font-size: 0.9em;
    padding-bottom: 2em;
    padding-left: 1em;
    padding-right: 1em;
    hr {
      display: block;
      height: 1px;
      width: calc(100% - 0.75em);
      position: relative;
      margin: 1em auto;
      border-left: none;
    }
  }
}

#categories {
  display: flex;
  justify-content: center;
  width: calc(1280px - 20vw);
  min-width: 60%;
  max-width: calc(100% - 60px);
  padding: 2% 0 1% 0;
  margin: auto auto 2% auto;
}

.category {
  text-align: center;
  margin: 0 $margin-between-categories 0 $margin-between-categories;

  img {
    max-width: 100px;
    width: 10vw;
    @media only screen and (max-width: 768px) {
      width: 20vw;
    }
  }
}

#categories {
  .VueCarousel {
    padding: 0;
  }
  .VueCarousel-wrapper {
    padding: 1em 0 1.5em;
  }
}
</style>
