// // const stagingconfig = require('./st.env')

module.exports = {
  NODE_ENV: 'production',

  CLIENT_ID: 'HktbLSF9PBdmaC5rZR55',
  PRODUCT_ID: '30',

  BACKEND_URL: 'https://juegos-be.planeta.guru',
  CLIENT_URL: 'https://pgc-api.clarovr.com',
  OAUTH_URL: 'https://oauth.clarovr.com/oauth',
  PROFILE_URL: 'https://account.clarovr.com',
  UDI_URL: 'http://clavr.co/udi',
  UPDATE_RATE: null // Set to null if you dont want to update the data

  // // STAGING_CONFIG: stagingconfig //* Do not touch
}
