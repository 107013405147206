<template>
  <div
    :class="{embed: $store.state.settings}"
    class="__nuxt-error-page layout-error">
    <div class="error">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="90"
        height="90"
        viewBox="0 0 48 48">
        <path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z" />
      </svg>
      <template v-if="statusCode === 404">
        <h1>¡LO SENTIMOS!</h1>
        <div
          v-if="message"
          class="message">{{ message }}</div>
        <div
          v-else
          class="message">
          {{ $store.getters.messages('404Message') }}
        </div>
        <!-- //// <p
          //// v-if="clientErrorDetails"
          //// class="description">{{ clientErrorDetails }}</p> -->
        <!-- //// <p class="description">
          //// <NuxtLink
          ////   class="error-link"
          ////   to="/">Regresa a la home</NuxtLink>
        //// </p> -->
      </template>
      <template v-else>
        <h1>¡OH, NO!</h1>
        <div class="message">{{ message }}</div>
        <p
          v-if="clientErrorDetails"
          class="description">
          {{ clientErrorDetails }}
        </p>
        <p
          v-else
          class="description">An error occurred while rendering the page. Check developer tools console for details.</p>
      </template>
      <!--
      ////<div class="logo">
      ////  <a
      ////    href="/"
      ////    target="_blank"
      ////    rel="noopener">Planeta Guru</a>
      ////</div>
       -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'NuxtError',
  props: {
    error: {
      type: Object,
      default: null
    }
  },
  head() {
    return {
      title: this.message || `Error: ${this.statusCode}`,
      link: this.$store.getters.headThemeResources
    }
  },
  computed: {
    title() {
      return this.error.title
    },
    statusCode() {
      return (this.error && this.error.statusCode) || 500
    },
    message() {
      try {
        return JSON.parse(this.error.message).message
      } catch (error) {
        return this.error.message
      }
    },
    clientErrorDetails() {
      return this.error.clientErrorDetails
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';
.__nuxt-error-page.embed {
  min-height: 70vh;
}
.__nuxt-error-page {
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  font-weight: 100 !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  min-height: 100vh;
}

.__nuxt-error-page h1 {
  max-width: 450px;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
}
.__nuxt-error-page svg {
  height: 4rem;
  width: auto;
}
.__nuxt-error-page .error {
  max-width: 450px;
}
.__nuxt-error-page .message {
  font-size: 1.25rem;
  margin-top: 15px;
  margin: 0.75em 0;
  line-height: 1.25em;
}
.__nuxt-error-page .description,
.__nuxt-error-page a {
  display: block;
  line-height: 21px;
  margin: 1.5em 0 10px;
  text-decoration: none;
  background-color: transparent;
  text-align: center;
  // margin-bottom: 10px;
}
.__nuxt-error-page .logo {
  position: absolute;
  left: 12px;
  bottom: 12px;
}
</style>
