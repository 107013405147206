/**
 * Doc
 * https://wiki.planeta.guru/doku.php?id=pg_juegos_backend#obtener_token_de_usuario
 */

export const state = () => ({
  // uCode: null
})

export const mutations = {
  // uCode(state, code) {
  //   state.uCode = code
  // }
}

export const actions = {
  async buildRequest({ dispatch, rootGetters, rootState }, payload) {
    let params = payload.params
    let headers = payload.headers
    // Add provider Param
    params = await dispatch('addProviderParam', { params }, { root: true })
    params.product = rootState.product
    if (rootState.token) {
      headers['Authorization'] = `Bearer ${rootState.token.accessToken}`
    } else {
      if (rootState.language) {
        params.language = rootState.language
      }
      if (this.$cookies.get('pg_udi_tkn')) {
        params.udi = this.$cookies.get('pg_udi_tkn')
      }
    }

    return this.$axios.request({
      crossDomain: true,
      url: `${rootState.env.BACKEND_URL}${payload.url}`,
      method: payload.method,
      timeout: rootState.timeout,
      headers,
      params,
      data: payload.data
    })
  },
  async makeRequest({ dispatch }, request) {
    try {
      const response = await dispatch('buildRequest', request)
      dispatch('log', { type: 'info', request: response }, { root: true })
      return response
    } catch (error) {
      let log = { message: error }
      if (error.response) {
        log.request = error.response
      }
      dispatch('log', { type: 'error', ...log }, { root: true })
      return error.response || { status: 500, error: error }
    }
  },
  async addAuthToRequest({ dispatch, rootGetters, rootState }, payload) {
    // TODO: deprecate this action
    let udiToken = null
    if (this.$cookies.get('pg_udi_tkn')) {
      udiToken = this.$cookies.get('pg_udi_tkn')
    }
    let params = payload.params
    let headers = payload.headers
    let ready = false
    // Add provider Param
    params = await dispatch('addProviderParam', { params }, { root: true })
    params.product = rootState.product
    if (rootState.token) {
      headers['Authorization'] = `Bearer ${rootState.token.accessToken}`
      ready = true
    } else {
      var language = rootState.language
      if (language) {
        params.language = language
      }
      if (udiToken) {
        params.udi = udiToken
      }
      ready = true
    }
    if (ready) {
      const fallback = payload.fallback.bind(payload.vue)
      await fallback(headers, params)
    }
  },
  async checkNicknameStatus({ dispatch }, nickName) {
    return await dispatch('buildRequest', {
      url: '/v1/users/status',
      method: 'GET',
      headers: {},
      params: {
        nickname: nickName
      }
    })
  },
  async editNickName({ dispatch }, nickname) {
    return await dispatch('buildRequest', {
      url: '/v1/users/edit/nickname',
      method: 'PUT',
      headers: {},
      params: {},
      data: { nickname }
    })
  },
  async editName({ dispatch }, payload) {
    return await dispatch('buildRequest', {
      url: '/v1/users/edit/name',
      method: 'PUT',
      headers: {},
      params: {},
      data: payload
    })
  },
  async editAvatar({ dispatch }, file) {
    const formData = new FormData()
    formData.append('file', file)
    return await dispatch('buildRequest', {
      url: '/v1/users/edit/avatar',
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      params: {},
      data: formData
    })
  },
  async getTransactions({ dispatch }, result) {
    return await dispatch('buildRequest', {
      url: '/v1/users/transactions',
      method: 'GET',
      headers: {},
      params: { result: result },
      data: null
    })
  },
  async getCategory({ dispatch }, payload) {
    return await dispatch('makeRequest', {
      url: '/v1/tags/' + payload.category,
      method: 'GET',
      headers: {},
      params: payload.params,
      data: null
    })
  },
  async getMeta({ dispatch }, meta) {
    return await dispatch('makeRequest', {
      url: '/v1/meta/' + meta,
      method: 'GET',
      headers: {},
      params: {},
      data: null
    })
  },
  async getMedals({ dispatch, rootState }) {
    let medalSlug = 'medal'
    if (rootState.settings && rootState.settings.medalSlug) {
      medalSlug = rootState.settings.medalSlug
    } else {
      dispatch(
        'log',
        { type: 'info', message: 'Using default medal slug: ' + medalSlug },
        { root: true }
      )
    }
    return await dispatch('getMeta', medalSlug)
  },
  async getCategories({ dispatch }) {
    return await dispatch('getMeta', 'category')
  },
  async getGallery({ dispatch, rootState }, gallery) {
    if (!gallery) {
      gallery = rootState.settings.galleryHome || 'home'
    }
    return await dispatch('makeRequest', {
      url: '/v1/galleries/' + gallery,
      method: 'GET',
      headers: {},
      params: {},
      data: null
    })
  },
  async getGame({ dispatch, rootGetters, rootState }, game) {
    const response = await dispatch('makeRequest', {
      url: '/v1/contents/' + game,
      method: 'GET',
      headers: {},
      params: {},
      data: null
    })
    if (response.status === 200) {
      const data = response.data.data ? response.data.data[0] : null
      let specs = {}
      specs[rootGetters.messages('category')] = data.tags
        .map(
          x =>
            `<a href="${rootState.env.BASE_URL}/category/${x.slug}">${
              x.title
            }</a>`
        )
        .join(', ')
      specs[rootGetters.messages('platform')] = data.platform
      specs[rootGetters.messages('size')] = data.size
      specs[rootGetters.messages('downloads')] = data.downloads
      specs[rootGetters.messages('rating')] = data.rating
      specs[rootGetters.messages('votes')] = data.rating_times
      specs[rootGetters.messages('multiplayer')] = data.multiplayer
      response._specs = specs
    }
    return response
  },
  async storeUserTracing({ dispatch, rootGetters, rootState }, data) {
    const response = await dispatch('makeRequest', {
      url: '/tracing',
      method: 'POST',
      headers: {},
      params: {},
      data: data
    })
    if (response.status === 201) {
      console.log(response)
    }
    return response
  },
  async searchGames({ dispatch }, searchQuery) {
    return await dispatch('makeRequest', {
      url: '/v1/contents',
      method: 'GET',
      headers: {},
      params: {
        q: encodeURIComponent(searchQuery)
      },
      data: null
    })
  },
  async getContentFile({ dispatch }, contentId) {
    if (!contentId) {
      return { status: 400, error: '[getContentFile] contentId required' }
    }
    return await dispatch('makeRequest', {
      url: `/v1/contents/${contentId}/file/0`,
      method: 'GET',
      headers: {},
      params: {},
      data: null
    })
  },
  async newOrder({ dispatch }, contentId) {
    if (!contentId) {
      return { status: 400, error: '[getContentFile] contentId required' }
    }
    return await dispatch('makeRequest', {
      url: '/v1/orders',
      method: 'put',
      headers: {
        'content-type': 'application/json'
      },
      params: {},
      data: {
        content_id: contentId,
        meta: { asdf: 'adsf' }
      }
    })
  },
  async userLogin({ dispatch, rootGetters, rootState }, ani) {
    if (ani === null || ani === undefined || ani === '') {
      return {
        status: 422,
        data: {
          error: true,
          message: 'El número es requerido',
          data: {
            error: 'El número es requerido'
          }
        }
      }
    }
    const response = await dispatch('makeRequest', {
      url: `/login/${ani}`,
      method: 'GET',
      headers: {},
      params: {}
    })
    if (response.status === 200) {
      console.log(response.data)
      const data = response.data ? response.data : null
      if (!data.error) {
        const url = data.data.urlLogin ? data.data.urlLogin : null
        if (url !== null) {
          window.location.href = url
        }
      }
    }
    return response
  }
}
