// // const stagingconfig = require('./st.env')

module.exports = {
  NODE_ENV: 'production',

  CLIENT_ID: 'PhVWeTR1TS1QRERFciJz',
  PRODUCT_ID: '10',

  BACKEND_URL: 'https://juegos-be.planeta.guru',
  CLIENT_URL: 'https://pgc-api.planeta.guru',
  OAUTH_URL: 'https://oauth.planeta.guru/oauth',
  PROFILE_URL: 'https://account.planeta.guru',
  UDI_URL: 'http://rxr.co/udi',
  UPDATE_RATE: 20000 // Set to null if you dont want to update the data

  // // STAGING_CONFIG: stagingconfig //* Do not touch
}
