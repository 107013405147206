var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  NODE_ENV: 'staging',

  CLIENT_ID: 'PhVWeTR1TS1QRERFciJz',
  PRODUCT_ID: '10',

  BACKEND_URL: 'https://staging.juegos-be.planeta.guru',
  CLIENT_URL: 'https://pgc-api.planeta.guru',
  OAUTH_URL: 'https://oauth.planeta.guru/oauth',
  PROFILE_URL: 'https://account.planeta.guru',
  UDI_URL: 'http://staging.rxr.co/udi',

  UPDATE_RATE: 20000, // Set to null if you dont want to update the data
  DEBUG_NOTIFICATION: true
})
