var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  NODE_ENV: '"development"',

  BACKEND_URL: 'http://pg-juegos-backend.test',
  CLIENT_URL: 'https://pgc-api.planeta.guru',
  OAUTH_URL: 'https://oauth.planeta.guru/oauth',
  PROFILE_URL: 'https://account.planeta.guru',
  UDI_URL: 'http://staging.rxr.co/udi',
  DEBUG_NOTIFICATION: true,

  UPDATE_RATE: 20000 // Set to null if you dont want to update the data
})
