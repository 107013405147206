<template>
  <div>
    <no-ssr>
      <notifications
        :reverse="true"
        :close-on-click="$store.state.debugConfig.notify == 'debug' ? false : true"
        :class="{infinite: $store.state.debugConfig.notify == 'debug'}"
        group="debug"
        class="debug"
        position="bottom right"/>
      <notifications
        group="user"
        class="user"
        position="top right"/>
    </no-ssr>
  </div>
</template>
<script>
export default {
  name: 'UserNotifications'
}
</script>
