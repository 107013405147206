import Notifications from 'vue-notification'
import Paginate from 'vuejs-paginate'
import Vue from 'vue'
import VueResource from 'vue-resource'

if (process.browser) {
  Vue.use(VueResource)
  Vue.use(Notifications)
  Vue.component('paginate', Paginate)
}

export default async context => {
  // clientsideLoad(context)
}
