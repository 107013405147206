const consola = require('consola')

export default {
  nuxtServerInit({ commit, dispatch }, { req }) {
    if (req && req.socket.remoteAddress) {
      dispatch('log', {
        type: 'info',
        message: `[LOG HE FRONT] conection: ${
          req.connection.remoteAddress
        }, remote: ${req.socket.remoteAddress}`
      })
      commit('set', { reqIP: req.socket.remoteAddress })
    }
    if (req && req.headers && req.headers['x-forwarded-for']) {
      commit('set', { reqProvider: req.headers['x-forwarded-for'] })
    }
    commit('set', { reqMethod: req.method || 'GET' })
  },
  handleSubscriptionRemember({ state, commit, getters }, callback) {
    const logged = state.profile ? true : false
    const subscribeReminder = state.settings
      ? state.settings.subscribeReminderClaroVR
      : null
    if (
      subscribeReminder &&
      subscribeReminder.frequency &&
      process.browser &&
      !logged
    ) {
      console.debug('[startSubscribeReminderClaroVR] Started Reminders')
      if (
        !state.settings['_meta'].subscribed &&
        !state.isRugVisible &&
        !state.popup.name
      ) {
        commit('popup', {
          name: 'subscribeReminderClaroVR'
        })
        console.debug('[startSubscribeReminderClaroVR] Opened the popup')
      } else {
        console.debug('[startSubscribeReminderClaroVR] Tried to open the popup')
      }
    } else {
      callback()
    }
  },
  handleGetContentFile({ state, commit, getters }, payload) {
    // Validate payload
    if (!payload.slug) {
      this.dispatch('log', {
        type: 'error',
        message: '[handleGetContentFile] missing "slug"',
        notify: true
      })
      return
    }
    // Variables
    var route = {
      name: 'download-slug',
      params: { slug: payload.slug }
    }
    // Decide action from on Content Type
    if (payload.content_type === 'browser_game') {
      // Open contet in a new window
      let routeData = this.$router.resolve(route)
      if (process.browser) {
        window.open(routeData.href, '_blank')
      } else {
        return routeData.href
      }
    } else if (payload.content_type === 'vr_video') {
      // Open youtube video in a new tab or return url (SSR)
      const videoUrl = payload.media
        ? payload.media.find(
            media => media.includes('youtube.com') || media.includes('youtu.be')
          )
        : null
      if (videoUrl) {
        if (process.browser) {
          window.open(videoUrl, '_blank')
        } else {
          return videoUrl
        }
      } else {
        this.dispatch('log', {
          type: 'error',
          message: '[handleGetContentFile] No youtube video found',
          notify: true
        })
      }
    } else {
      // Go to download page
      this.$router.push(route)
    }
  },
  signin({ state, dispatch }, data) {
    console.log(data)
    // Set redirectUri
    let redirectUri = process.browser ? window.location.href : null
    if (data.redirectUri) {
      redirectUri = data.redirectUri
    } else {
      this.dispatch('log', {
        type: 'error',
        message: 'signin: Missing redirectUri'
      })
    }
    redirectUri = encodeURIComponent(redirectUri)
    // Set codeChallenge
    let codeChallenge
    if (this.$cookies.get('pg_cc')) {
      codeChallenge = this.$cookies.get('pg_cc')
    } else if (state.codeChallenge) {
      codeChallenge = state.codeChallenge
    } else {
      this.dispatch('log', {
        type: 'warn',
        message: 'Missin code Challenge'
      })
    }
    // Build Signin URL
    let signinUrl = `${state.env.OAUTH_URL}/auth/authorize/signin`
    let signinUrlParams = [
      'response_type=code',
      `client_id=${state.env.CLIENT_ID}`,
      `redirect_uri=${redirectUri}`,
      `product_id=${state.env.PRODUCT_ID}`,
      `code_challenge=${codeChallenge}`,
      'code_challenge_method=S256',
      `state=${data.state || 'login'}`
    ]
    // Add Saved Query Params
    if (state.params) {
      let oauthParams = [
        { name: 'locale', defalt: 'es_AR' },
        { name: 'prvdr' },
        { name: 'userID', defalt: this.$cookies.get('pg_ani') },
        { name: 'service' },
        { name: 'mdsp' },
        { name: 'msfw' },
        { name: 'suscrod', disabled: state.reqMethod != 'POST' }
      ]
      oauthParams.forEach(param => {
        if (!param.disabled) {
          if (state.params[param.name]) {
            signinUrlParams.push(param.name + '=' + state.params[param.name])
          } else if (param.defalt) {
            signinUrlParams.push(param.name + '=' + param.defalt)
          }
        }
      })
    }
    // Add Params to URL
    signinUrl += '?' + signinUrlParams.join('&')
    // Do we have a custom loginUrl?
    if (!data.oauth && (state.settings && state.settings.loginUrl)) {
      signinUrl = state.settings.loginUrl
    }

    let params = this.$cookies.get('pg_params')

    if (params) {
      let utm_source = params.utm_source
      let utm_medium = params.utm_medium
      let utm_campaign = params.utm_campaign

      let q = signinUrl.indexOf('?') >= 0
      if (utm_source) signinUrl += (q ? '&' : '?') + 'utm_source=' + utm_source

      q = signinUrl.indexOf('?') >= 0
      if (utm_medium) signinUrl += (q ? '&' : '?') + 'utm_medium=' + utm_medium

      q = signinUrl.indexOf('?') >= 0
      if (utm_campaign)
        signinUrl += (q ? '&' : '?') + 'utm_campaign=' + utm_campaign
    }

    dispatch('log', {
      type: 'info',
      message: '[signin] url: ' + signinUrl
    })

    // Redirect to Signin URL
    if (data.redirect) {
      data.redirect(signinUrl)
    } else {
      window.location.href = signinUrl
    }
  },
  async getUser({ state, commit, dispatch }, payload) {
    if (payload.vue.$cookies.get('pg_tkn')) {
      const requestUser = async (headers, params) => {
        try {
          if (this.$cookies.get('pg_regenerate') && process.server) {
            params.regenerate = true
          }
          if (this.$cookies.get('pg_force_subscribed') && process.server) {
            params.subscribed = true
          }
          let response = await this.$axios.request({
            method: 'get',
            url: `${state.env.BACKEND_URL}/v1/users`,
            withCredentials: true,
            timeout: state.timeout,
            params,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${state.token.accessToken}`
            }
          })
          dispatch('log', {
            type: 'info',
            request: response
          })
          dispatch('setUserData', response.data.data)
          if (params.regenerate) {
            this.$cookies.remove('pg_regenerate')
          }
          if (params.subscribed) {
            this.$cookies.remove('pg_force_subscribed')
          }
          if (process.server) {
            try {
              dispatch('log', {
                type: 'info',
                message:
                  '[ Setttings Meta ] ' +
                  JSON.stringify(response.data.data.settings['_meta'])
              })
            } catch (error) {}

            try {
              dispatch('log', {
                type: 'info',
                message:
                  '[ Subscriptions ] ' +
                  JSON.stringify(response.data.data.subscriptions)
              })
            } catch (error) {}

            try {
              dispatch('log', {
                type: 'info',
                message:
                  '[ Profile ] ' + JSON.stringify(response.data.data.profile)
              })
            } catch (error) {}

            try {
              dispatch('log', {
                type: 'info',
                message:
                  '[ tyc ] ' +
                  JSON.stringify(response.data.data.settings.pages.TyC)
              })
            } catch (error) {}
          }

          if (payload.fallback) {
            payload.fallback()
          }
        } catch (error) {
          dispatch('log', {
            type: 'error',
            request: error
          })
          if (
            (error.response && error.response.status === 401) ||
            error.status === 401
          ) {
            dispatch('removeCookies', {
              vue: payload.vue
            })
          }
        }
      }

      await dispatch('backend/addAuthToRequest', {
        vue: payload.vue,
        route: payload.vue.$route,
        headers: {},
        params: {},
        fallback: requestUser
      })
    }
  },
  async addProviderParam({ state, commit, dispatch, getters }, payload) {
    // Find host
    const provider = getters.providerHost
    let params = payload.params
    if (payload && payload.params) {
      if (state.params && state.params.provider) {
        params.provider = state.params.provider
      } else if (provider && provider.provider) {
        params.provider = provider.provider
      } else if (state.reqProvider) {
        params.provider = state.reqProvider
      }
    } else {
      dispatch('log', {
        type: 'warn',
        message: '[addProviderParam] No params provided'
      })
    }
    // dispatch('log', {
    //   type: 'info',
    //   message: `[PARAM-PROVIDER]: state.params.provider: ${state.params?.provider} - provider.provider: ${provider?.provider} - state.reqProvider: ${state.reqProvider}`
    // })
    return params
  },
  async getSettings({ state, commit, dispatch, getters }, payload) {
    // Request Settings info
    let udiToken = null
    if (this.$cookies.get('pg_udi_tkn')) {
      udiToken = this.$cookies.get('pg_udi_tkn')
    }
    let params = {
      product: state.product
    }
    // Add provider Param
    params = await dispatch('addProviderParam', { params })
    let headers = {
      'Content-Type': 'application/json'
    }
    let ready = false
    if (state.token) {
      headers['Authorization'] = `Bearer ${state.token.accessToken}`
      ready = true
    } else {
      if (state.language) {
        params.language = state.language
      }
      if (udiToken) {
        params.udi = udiToken
      }
      ready = true
      // params.language = vue.$store.getters.language()
    }
    if (this.$cookies.get('pg_regenerate') && process.server) {
      params.regenerate = true
    }
    if (this.$cookies.get('pg_force_subscribed') && process.server) {
      params.subscribed = true
    }

    if (ready) {
      try {
        let response = await this.$axios.request({
          method: 'get',
          url: `${state.env.BACKEND_URL}/v1/settings`,
          withCredentials: true,
          params: params,
          headers: headers,
          timeout: state.timeout
        })
        dispatch('log', {
          type: 'info',
          request: response
        })
        if (params.regenerate) {
          this.$cookies.remove('pg_regenerate')
        }
        if (params.subscribed) {
          this.$cookies.remove('pg_force_subscribed')
        }

        let settings = response.data.data

        commit('set', settings)

        if (payload.callback) payload.callback()
      } catch (error) {
        dispatch('log', {
          type: 'error',
          request: error
        })
        throw error
      }
    }
  },
  removeCookies({ state, commit, dispatch }) {
    this.$cookies.remove('pg_ani')
    this.$cookies.remove('pg_cc')
    this.$cookies.remove('pg_cc_oath')
    this.$cookies.remove('pg_cd')
    this.$cookies.remove('pg_cv')
    this.$cookies.remove('pg_cv_oath')
    this.$cookies.remove('pg_udi_tkn')
    this.$cookies.remove('pg_udi_tkn_exp')
    this.$cookies.remove('pg_tkn')
    this.$cookies.remove('pg_lgd')
    this.$cookies.remove('pg_ucode')
    this.$cookies.remove('pg_dob')
    commit('set', { token: null, profile: null })
    dispatch('log', {
      type: 'info',
      message: 'Removed Cookies & token and profile set to Null'
    })
  },
  log({ state, commit, dispatch }, payload) {
    /**
     * Call for this Action:
     *
     * context.store.dispatch('log', {
        type: 'info',
        request: response
        message: 'Some String',
        notify: true
     *  })
     */

    // Set Message
    let message = ''
    // Add Remote Address
    if (state.reqIP) {
      message += state.reqIP
    }
    // Add Date
    var date = new Date()
    message += ` [${date}]`
    // Add request Info
    if (payload.request) {
      // Add request
      try {
        message += ` "${payload.request.request._header
          .split('\n')[0]
          .replace(/\r?\n|\r/g, '')}"`
      } catch (error) {}
      // Add status Code
      if (payload.request.status) {
        message += ` ${payload.request.status}`
      } else if (payload.request.response && payload.request.response.status) {
        message += ` ${payload.request.response.status}`
      } else {
        try {
          message += ` ${payload.request}`
        } catch (error) {}
      }
      // Add REFERER
      if (payload.request.config && payload.request.config.url) {
        message += ` "${payload.request.config.url}"`
      } else if (payload.request.url) {
        message += ` "${payload.request.url}"`
      }
      // Add User Agent
      if (
        payload.request.config &&
        payload.request.config.headers['User-Agent']
      ) {
        message += ` "${payload.request.config.headers['User-Agent']}"`
      } else if (
        payload.request.headers &&
        payload.request.headers['User-Agent']
      ) {
        message += ` "${payload.request.headers['User-Agent']}"`
      }
    }

    // Print log
    if (process.browser) {
      let $notify
      let isDebugActive = state.debugConfig.notify === 'debug'
      let displayNotify = payload.notify || isDebugActive
      let notifyConfig = {
        duration: isDebugActive ? -1 : 2500
      }
      let notifyContent = {
        title: isDebugActive && payload.request ? payload.message || '' : '',
        text:
          isDebugActive && payload.request
            ? message
            : payload.message || message
      }
      try {
        $notify = this.$router.app.$root.$notify
      } catch (error) {
        console.warn('[Log] No $notify', error)
      }
      // Print log on client
      switch (payload.type) {
        case 'error':
          if (payload.message) {
            console.error(message, payload.message)
          } else {
            console.error(message)
          }
          if (displayNotify && $notify) {
            $notify({
              group: 'debug',
              type: 'error',
              title: `<i class="fa fa-times"></i> Error: ${
                notifyContent.title
              }`,
              text: notifyContent.text,
              ...notifyConfig
            })
          }
          break
        case 'warn':
          if (payload.message) {
            console.warn(message, payload.message)
          } else {
            console.warn(message)
          }
          if (displayNotify && $notify) {
            $notify({
              group: 'debug',
              type: 'warn',
              title: `<i class="fa fa-exclamation-triangle"></i> Warning: ${
                notifyContent.title
              }`,
              text: notifyContent.text,
              ...notifyConfig
            })
          }
          break
        case 'debug':
          if (payload.message) {
            console.debug(message, payload.message)
          } else {
            console.debug(message)
          }
          if (displayNotify && $notify) {
            $notify({
              group: 'debug',
              type: 'debug',
              title: `<i class="fa fa-bug"></i> Debug: ${notifyContent.title}`,
              text: notifyContent.text,
              ...notifyConfig
            })
          }
          break
        case 'info':
          if (payload.message) {
            console.info(message, payload.message)
          } else {
            console.info(message)
          }
          if (displayNotify && $notify) {
            $notify({
              group: 'debug',
              type: 'info',
              title: `<i class="fa fa-info"></i> Info: ${notifyContent.title}`,
              text: notifyContent.text,
              ...notifyConfig
            })
          }
          break
        default:
          if (payload.message) {
            console.log(message, payload.message)
          } else {
            console.log(message)
          }
          if (displayNotify && $notify) {
            $notify({
              group: 'debug',
              type: 'log',
              title: `Log: ${notifyContent.title}`,
              text: notifyContent.text,
              ...notifyConfig
            })
          }
          break
      }
    } else {
      // Add Message
      if (payload.message) {
        message += ' ' + payload.message
      }
      // Print log on Server
      switch (payload.type) {
        case 'info':
          consola.info(message)
          break
        case 'error':
          consola.error(message)
          break
        case 'warn':
          consola.warn(message)
          break
        case 'debug':
          consola.debug(message)
          break
        default:
          consola.log(message)
          break
      }
    }
  },
  handleSubscribe({ state, commit, getters, dispatch }) {
    if (getters.hasRug) {
      commit('set', { isRugVisible: true })
    } else if (!state.profile && process.browser) {
      dispatch('log', {
        type: 'warn',
        message:
          '[handleSubscribe] settings.anonymousSuscription or settings.paymentMethods not found or set to false. You will be redirected to oAuth'
      })
      this.$cookies.set('pg_subscribe', true, { path: '/' })
      dispatch('signin', { state: 'subscribe' })
    } else {
      dispatch('log', {
        type: 'warn',
        message:
          '[handleSubscribe] getters.hasRug is null or false. No valid RUG method found'
      })
    }
  },
  toggleFullScreen({ state, commit }) {
    const bodyScrollLock = require('body-scroll-lock')
    const targetElement = document.querySelector('html')
    if (state.isFullScreenActive) {
      bodyScrollLock.enableBodyScroll(targetElement)
      commit('set', { isFullScreenActive: false })
    } else {
      bodyScrollLock.disableBodyScroll(targetElement)
      commit('set', { isFullScreenActive: true })
    }
  },
  setUserData({ state, commit, getters, dispatch }, data) {
    const providerHostName = getters.providerHost
      ? getters.providerHost.genericName
      : null
    Object.keys(data).forEach(key => {
      let userData = {}
      if (key === 'paymentMethods' && providerHostName) {
        // Filter Payment Methods by provider
        let paymentMethods = {}
        Object.keys(data[key]).forEach(type => {
          paymentMethods[type] = data[key][type].filter(method => {
            return method.name.toLowerCase() === providerHostName.toLowerCase()
          })
        })
        dispatch('log', {
          type: 'info',
          message:
            '[ setUserData ] Filtered paymentMethods by provider: ' +
            providerHostName
        })
        userData[key] = paymentMethods
      } else {
        userData[key] = data[key]
      }
      commit('set', userData)
    })
  },
  handleDirectAccess({ state, commit }) {
    //const logged = state.profile ? true : false
    let isEntelPeru = false

    if (
      state.settings._meta.carrier &&
      state.settings._meta.carrier.indexOf('pe.entel') >= 0
    )
      isEntelPeru = false // Popup apagado para Peru

    if (isEntelPeru) {
      commit('popup', {
        name: 'directAccess'
      })
    }
  },
  handleTagManager({ state, commit }) {
    const logged = state.profile ? true : false

    //if (!logged) return

    let isClaroPeru = false

    if (state.settings._meta.country == 'PE') isClaroPeru = true

    // if (!isClaroPeru) return

    const googleScript = function(w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })

      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      f.parentNode.insertBefore(j, f)
    }
    googleScript(window, document, 'script', 'dataLayer', 'GTM-KXNF426')
  }
}
