<template>
  <div
    id="app"
    :class="{fullscreen : $store.state.isFullScreenActive}"
    class="layout-default">
    <UserNotifications/>
    <App/>
  </div>
</template>
<script>
import App from '@/App.vue'
import UserNotifications from '@/components/UserNotifications.vue'

export default {
  components: {
    App,
    UserNotifications
  },
  head() {
    return {
      link: this.$store.getters.headThemeResources
    }
  },
  data() {
    return {
      nav: undefined,
      mode: undefined,
      country: undefined,
      telco: undefined,
      tyc: undefined,
      code: undefined
    }
  },
  computed: {
    params() {
      const params = new URLSearchParams()
      params.append('nav', this.nav)
      params.append('mode', this.mode)
      params.append('country', this.country)
      params.append('telco', this.telco)
      params.append('code', this.code)
      this.$cookies.set('pg_cd', this.code, {path: '/'}) //eslint-disable-line
      return params
    }
  },
  mounted() {
    this.checkIsMobileScreen()
    if (process.browser) {
      this.updateUser()
    }
  },
  methods: {
    updateUser() {
      if (
        process.browser &&
        this.$store.state.token &&
        this.$store.state.env.UPDATE_RATE
      ) {
        setInterval(() => {
          this.$store.dispatch('getUser', { vue: this })
        }, this.$store.state.env.UPDATE_RATE)
      }
    },
    checkIsMobileScreen() {
      var handleIsMobile = function(media) {
        if (media.matches) {
          this.$store.commit('set', { isMobileScreen: true })
        } else {
          this.$store.commit('set', { isMobileScreen: false })
        }
      }
      handleIsMobile = handleIsMobile.bind(this)
      var maxMobile = window.matchMedia('(max-width: 768px)')
      handleIsMobile(maxMobile)
      maxMobile.addListener(handleIsMobile)
    }
  }
}
</script>

<style lang="scss">
@import '@/sass/main.sass';

*,
*:after,
*:before {
  box-sizing: border-box;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: none;
}

.button-download {
  font-size: 2rem;
  text-align: center;
  border-style: none;
  border-radius: 0.15em;
  width: 100%;
  max-width: 8em;
  font-weight: bold;
  padding: 0.2em 0.5em;
  cursor: pointer;
  @include s(font-size, 1.75rem, 2rem);
  &.expanded {
    max-width: none;
  }
}

.button-download-little {
  border-radius: 8px;
  max-width: 2em;
  margin-left: 11px;
}
.fa {
  font-family: FontAwesome, fontawesome !important;
  &:before {
    font-family: FontAwesome, fontawesome !important;
  }
}
.router-view-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
