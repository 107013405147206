<template >
  <section
    id="rug"
    :key="'rug' + paymentMethod.id"
    @click.self="close">
    <iframe
      v-show="!loading"
      id="rug-iframe"
      @load="iframeLoaded"/>
    <main
      v-if="loading"
      class="statusMessage">
      <p>{{ $store.getters.messages('loading') }}...</p>
  </main></section>
</template>
<script>
import {
  CarpetFitter,
  Ammo
} from '../dependencies/rug-carpet-fitter/carpet-fitter.js'
export default {
  name: 'RUG',
  data() {
    return {
      status: 'waiting',
      loading: true
    }
  },
  computed: {
    paymentMethod() {
      return (
        this.$store.state.selectedRugPaymentMethod ||
        this.$store.getters.defaultRugPaymentMethod
      )
    }
  },
  mounted() {
    if (this.$store.getters.hasRug && this.status === 'waiting') {
      this.status = 'loading'
      this.callRug()
    }
  },
  methods: {
    close() {
      if (this.$route.name === 'download-slug') {
        const gameSlug = this.$route.params.slug
        this.$router.push({
          name: 'detail-slug',
          params: { slug: gameSlug }
        })
      }
      this.$store.commit('set', {
        isRugVisible: false,
        selectedRugPaymentMethod: null
      })
    },
    callRug() {
      // Set AMMO Frontend
      let frontendAmmo = this.paymentMethod.frontendAmmo
      // On Success
      const onSuccess = a => {
        this.$store.dispatch('log', {
          type: 'info',
          message: '[RUG - onSuccess] ' + a
        })
        if (!this.$store.state.profile) {
          this.$store.dispatch('signin', { vue: this.$root })
        } else {
          this.$cookies.set('pg_regenerate', true, {
            path: '/'
          })
          this.$cookies.set('pg_force_subscribed', true, {
            path: '/'
          })
          window.location = window.location.origin + process.env.baseUrl
        }
      }
      const onSuccessBind = onSuccess.bind(this)
      // On Error
      const onError = a => {
        this.$store.dispatch('log', {
          type: 'error',
          message: '[RUG - onError] ' + a,
          notify: true
        })
        this.$cookies.set('pg_regenerate', true, {
          path: '/'
        })
        if (!this.$store.state.profile) {
          this.$store.dispatch('signin', { vue: this.$root })
        } else {
          this.close()
        }
      }
      const onErrorBind = onError.bind(this)
      if (frontendAmmo) {
        // Set Context
        let context = frontendAmmo.context
        // Add ANI
        try {
          context.ani = this.$store.state.profile.phones[0].msisdn
        } catch (error) {}
        // Add Campaign Params
        let campaignParams = {}
        if (context.campaignParams) {
          try {
            campaignParams = JSON.parse(
              decodeURIComponent(context.campaignParams)
            )
          } catch (error) {
            this.$store.dispatch('log', {
              type: 'warning',
              message: `[callRug] Error decoding campaignParams ${error}`,
              notify: true
            })
          }
        }
        let storedParams = {}
        let storedCampaignParams = {}
        if (this.$store.state.params) {
          Object.keys(this.$store.state.params).forEach(key => {
            if (key == 'campaignParams') {
              try {
                storedCampaignParams = JSON.parse(
                  this.$store.state.params.campaignParams
                )
              } catch (error) {}
            } else if (key === 'paymentType') {
              context.paymentType = this.$store.state.params.paymentType
            } else {
              const newKey = key.replace('utm_', '')
              storedParams[newKey] = this.$store.state.params[key]
            }
          })
        }
        campaignParams = {
          ...campaignParams,
          ...storedParams,
          ...storedCampaignParams
        }
        if (Object.keys(campaignParams).length) {
          const encodedCampaignParams = JSON.stringify(campaignParams)
          context.campaignParams = encodedCampaignParams
        }

        this.$store.dispatch('log', {
          type: 'debug',
          message: `[callRug] Ammo: ${JSON.stringify([
            frontendAmmo.application,
            frontendAmmo.module,
            frontendAmmo.mode,
            frontendAmmo.operation
          ])}`
        })
        this.$store.dispatch('log', {
          type: 'debug',
          message: `[callRug] Context: ${JSON.stringify(context)}`
        })

        // Call CarpetFitter
        var carpetFitter = new CarpetFitter(onSuccessBind, onErrorBind, {
          environment:
            this.$store.state.env.NODE_ENV === 'production' ? 'prod' : 'staging'
        })
        carpetFitter.startFlow(
          new Ammo(
            frontendAmmo.application,
            frontendAmmo.module,
            frontendAmmo.mode,
            frontendAmmo.operation
          ),
          'rug-iframe',
          context
        )
      } else {
        this.$store.dispatch('log', {
          type: 'error',
          message: `[RUG] no frontendAmmo`,
          notify: true
        })
        this.close()
      }
    },
    iframeLoaded(event, test) {
      if (event.target.getAttribute('src')) {
        if (this.status != 'mounted') {
          this.$store.dispatch('log', {
            type: 'info',
            message: `[iframeLoaded] iframe finished loading`
          })
          this.status = 'mounted'
          this.loading = false
        }
      }
    }
  }
}
</script>
<style lang="sass">
@import '@/sass/resources.sass';
section#rug
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    cursor: pointer
    #rug-iframe
      width: 100%;
      height: 100%;
      border: none
      cursor: default
</style>
