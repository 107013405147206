var merge = require('webpack-merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
  NODE_ENV: 'staging',

  CLIENT_ID: 'HktbLSF9PBdmaC5rZR55',
  PRODUCT_ID: '30',

  BACKEND_URL: 'https://staging.juegos-be.clarovr.com',
  CLIENT_URL: 'https://pgc-api.clarovr.com',
  OAUTH_URL: 'https://oauth.clarovr.com/oauth',
  PROFILE_URL: 'https://account.clarovr.com',
  UDI_URL: 'http://staging.clavr.co/udi',

  UPDATE_RATE: 20000, // Set to null if you dont want to update the data
  DEBUG_NOTIFICATION: true
})
