import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _7d790132 = () => interopDefault(import('../src/pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _1cd9f278 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _85a81620 = () => interopDefault(import('../src/pages/MyGames.vue' /* webpackChunkName: "pages/MyGames" */))
const _32e8b59a = () => interopDefault(import('../src/pages/PageNotFound.vue' /* webpackChunkName: "pages/PageNotFound" */))
const _979f0072 = () => interopDefault(import('../src/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _02f16b98 = () => interopDefault(import('../src/pages/register.vue' /* webpackChunkName: "pages/register" */))
const _fd802946 = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _3b0cb054 = () => interopDefault(import('../src/pages/profile/wallet.vue' /* webpackChunkName: "pages/profile/wallet" */))
const _1d239ec4 = () => interopDefault(import('../src/pages/category/_id.vue' /* webpackChunkName: "pages/category/_id" */))
const _2f104a41 = () => interopDefault(import('../src/pages/detail/_slug.vue' /* webpackChunkName: "pages/detail/_slug" */))
const _27e57e78 = () => interopDefault(import('../src/pages/download/_slug.vue' /* webpackChunkName: "pages/download/_slug" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2634c265 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

Vue.use(Router)

if (process.client) {
  window.history.scrollRestoration = 'manual'
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/favorites",
      component: _7d790132,
      name: "favorites"
    }, {
      path: "/login",
      component: _1cd9f278,
      name: "login"
    }, {
      path: "/MyGames",
      component: _85a81620,
      name: "MyGames"
    }, {
      path: "/PageNotFound",
      component: _32e8b59a,
      name: "PageNotFound"
    }, {
      path: "/profile",
      component: _979f0072,
      name: "profile"
    }, {
      path: "/register",
      component: _02f16b98,
      name: "register"
    }, {
      path: "/search",
      component: _fd802946,
      name: "search"
    }, {
      path: "/profile/wallet",
      component: _3b0cb054,
      name: "profile-wallet"
    }, {
      path: "/category/:id?",
      component: _1d239ec4,
      name: "category-id"
    }, {
      path: "/detail/:slug?",
      component: _2f104a41,
      name: "detail-slug"
    }, {
      path: "/download/:slug?",
      component: _27e57e78,
      name: "download-slug"
    }, {
      path: "/",
      component: _27eb63ad,
      name: "index"
    }, {
      path: "/:slug",
      component: _2634c265,
      name: "slug"
    }],

    fallback: false
  })
}
