export default function({ route, app, store, redirect }) {
  // Request Auth
  const requiresAuthList = ['download-slug', 'profile', 'profile-wallet']
  const requiresAuth = requiresAuthList.indexOf(route.name) > -1
  if (requiresAuth) {
    if (
      !(app.$cookies.get('pg_tkn') || route.query.code || route.query.token) &&
      !store.getters.hasRug
    ) {
      let redirectUri = store.state.href
      store.dispatch('signin', {
        vue: app,
        redirect,
        redirectUri: redirectUri
      })
    }
  }
  if (
    route.name == 'detail-slug' &&
    store.state.product == 'html5' &&
    !store.state.gameContent
  ) {
    redirect('/download/' + route.params.slug)
  }
}
