<template>
  <div
    :class="{active:isMenuActive}"
    class="menu-main">
    <HamburguerIcon
      v-if="hamburger"
      @click="$emit('hamburger-click')"/>
    <ul>
      <li
        v-for="link in links"
        :key="link.title">
        <MenuLink
          :link="link"
          @click="toggleSubmenu(link.title)"/>
        <ul v-if="link.links && activeSubmenu === link.title ">
          <li
            v-for="link in link.links"
            :key="link.title">
            <MenuLink :link="link"/>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import HamburguerIcon from '@/components/HamburguerIcon.vue'
import MenuLink from '@/components/MenuLink.vue'
export default {
  name: 'Menu',
  components: {
    HamburguerIcon,
    MenuLink
  },
  props: {
    isMenuActive: {
      type: Boolean,
      default: true
    },
    hamburger: {
      type: Boolean,
      default: false
    },
    showPlanAnual: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeSubmenu: null
    }
  },
  computed: {
    isLogged() {
      return this.$store.state.profile ? true : false
    },
    links() {
      const categories = this.$store.state.categories || []
      const links = [
        {
          title: this.$store.getters.messages('login'),
          click: () => {
            this.$store.dispatch('signin', { vue: this.$root })
          },
          hidden: this.isLogged
        },
        {
          title: 'Plan Anual',
          click: () => {
           window.location.href = "https://login.clarovr.com/"
          },
          hidden: !this.showPlanAnual && !this.isLogged
        },
        {
          title: this.$store.getters.messages('myProfile'),
          to: '/profile',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('category'),
          links: [
            {
              title: 'Category 1',
              to: '/'
            }
          ],
          links: categories.map(link => {
            return {
              title: link.title,
              to: { name: 'category-id', params: { id: link.slug } }
            }
          })
        },
        {
          title: this.$store.getters.messages('downloads'),
          to: '/profile/wallet',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('myGames'),
          to: '/mygames',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('myFavorites'),
          to: '/favorites',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('mySubscription'),
          click: this.unsubscribe,
          hidden: true
        },
        {
          title: this.$store.getters.messages('cancelSubscription'),
          click: this.unsubscribe,
          hidden: !(
            this.$store.state.subscriptions &&
            this.$store.state.subscriptions.mainSubscriptionSlug
          )
        },
        {
          title: this.$store.getters.messages('helpMe'),
          href: 'mailto:soporte@planeta.guru',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('backHome'),
          to: '/',
          hidden: !this.isLogged
        },
        {
          title: this.$store.getters.messages('logout'),
          click: () => {
            this.$store.commit('logOut', this.$root)
          },
          hidden: !this.isLogged
        }
      ]
      return links.filter(link => !link.hidden)
    }
  },
  methods: {
    toggleSubmenu(id) {
      if (this.activeSubmenu === id) {
        this.activeSubmenu = null
      } else {
        this.activeSubmenu = id
      }
    },
    sendToSubscriptions() {
      this.$store.commit('set', { loading: true })
      this.$store.dispatch('pgserver/goToSubscriptions')
    },
    unsubscribe() {
      this.$store.commit('popup', {
        name: 'unsubscribe'
      })
    }
  }
}
</script>
<style lang="scss">
.menu-main {
  position: fixed;
  left: -12rem;
  top: 0;
  width: 12rem;
  height: 100%;
  padding-top: 5rem;
  transition: 0.25s;
  transition-duration: 0.25s;
  transition-property: left, box-shadow;
  z-index: 90;
  overflow: auto;
  &.active {
    left: 0;
  }
  .button-hamburger {
    position: absolute;
    top: 1.75rem;
    left: 0.75rem;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 2.5rem;
  }
  li {
    padding: 0;
    width: 100%;
  }
}
</style>
