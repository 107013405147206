/*
    Source: https://bitbucket.org/itbaf_team/rug-carpet-fitter/src/master/
*/
export class CarpetFitter {
  constructor(onSuccess, onFail, settings) {
    this.settings = settings

    var baseUrls = {
      prod: 'https://planeta.guru/concierge/v1/do/',
      staging: 'https://staging.planeta.guru/concierge/v1/do/',
      rxr: 'http://rxr.co/concierge/v1/do/',
      'rxr-staging': 'http://staging.rxr.co/concierge/v1/do/'
    }

    if (settings.environment == 'custom' && settings.customEnvironmentUri) {
      this.baseUrl = settings.customEnvironmentUri
    } else {
      this.baseUrl = baseUrls[settings.environment] || baseUrls.staging
    }

    // Lifted from: https://www.codeproject.com/Tips/585663/Communication-with-Cross-Domain-IFrame-A-Cross-Bro
    var eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attackEvent'
    var eventer = window[eventMethod]

    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message'

    eventer(
      messageEvent,
      function(e) {
        switch (e.data.event) {
          case 'success':
            console.log('Success from the Fitter!')
            onSuccess(e.data.parameters.message || '')
            break
          case 'failure':
            console.log('Failure from the Fitter!')
            onFail(e.data.parameters.message || '')
            break
        }
      },
      false
    )
  }

  startFlow(ammo, iframeId, context = {}) {
    var context2 = this.serializeToQueryString(context)

    var iframeUrl =
      this.baseUrl +
      ammo.application +
      '/' +
      ammo.module +
      '/' +
      ammo.mode +
      '/' +
      ammo.operation +
      '?' +
      context2

    console.log(iframeUrl)

    document.getElementById(iframeId).src = iframeUrl
  }

  serializeToQueryString(object) {
    var str = []
    for (var p in object) {
      if (object.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(object[p]))
      }
    }
    return str.join('&')
  }
}

export class Ammo {
  constructor(application, module, mode, operation) {
    this.application = application
    this.module = module
    this.mode = mode
    this.operation = operation
  }
}
