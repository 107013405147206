<template>
  <div
    v-if="floatingFooter"
    ref="floatingFooterContainer"
    :style="{ height: isfloatingFooterFixed ? `${floatingFooterHeight}px` : 'auto' }"
    class="floating-footer">
    <div
      ref="floatingFooterContent"
      :class="{fixed: isfloatingFooterFixed}"
      class="content">
      <div class="wrapper">
        <button
          v-if="floatingFooter.suscribe"
          @click="$store.dispatch('handleSubscribe')">
          {{ $store.getters.messages('subscribe').toUpperCase() }}
        </button>
        <p v-if="floatingFooter.content">{{ floatingFooter.content }}</p>
        <template v-if="floatingFooter.tyc">
          <a
            v-if="this.$store.state.settings && this.$store.state.settings.footerLinks && this.$store.state.settings.footerLinks.TyC && this.$store.state.settings.footerLinks.TyC.url"
            :href="this.$store.state.settings.footerLinks.TyC.url"
            target="_blank"
            class="link-tyc">
            {{ $store.getters.messages('tyc') }}
          </a>
          <nuxt-link
            v-else
            to="/TyC"
            class="link-tyc">
            {{ $store.getters.messages('tyc') }}
          </nuxt-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FloatingFooter',
  data() {
    return {
      isfloatingFooterFixed: true,
      floatingFooterHeight: 0
    }
  },
  computed: {
    floatingFooter() {
      return this.$store.state.settings.floatingFooter
    }
  },
  mounted() {
    if (process.browser && this.floatingFooter) {
      this.watchFloatingFooter()
    }
  },
  methods: {
    watchFloatingFooter() {
      const floatingFooterElement = this.$refs.floatingFooterContent
      const floatingFooterContainerElement = this.$refs.floatingFooterContainer
      if (floatingFooterElement && floatingFooterContainerElement) {
        // Set initical Height
        this.floatingFooterHeight = floatingFooterElement.offsetHeight
        // Set Height on resize
        let windowResize = () => {
          if (
            floatingFooterElement.offsetHeight !== this.floatingFooterHeight
          ) {
            this.floatingFooterHeight = floatingFooterElement.offsetHeight
            this.$store.dispatch('log', {
              type: 'debug',
              message: '[getfloatingFooterHeight] floatingFooter was resized'
            })
          }
        }
        window.onresize = windowResize.bind(this)
        // Toggle fixed position
        let windowScroll = event => {
          const scrollBottom = window.scrollY + window.innerHeight
          if (
            scrollBottom >=
            floatingFooterContainerElement.offsetTop + this.floatingFooterHeight
          ) {
            if (this.isfloatingFooterFixed) {
              this.isfloatingFooterFixed = false
            }
          } else {
            if (!this.isfloatingFooterFixed) {
              this.isfloatingFooterFixed = true
            }
          }
        }
        window.onscroll = windowScroll.bind(this)
      }
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/resources.sass';
.floating-footer {
  .content {
    position: relative;
    width: 100%;
    z-index: 2;
    text-align: center;
    margin: 0;
    font-size: 0.5em;
    &.fixed {
      position: fixed;
      bottom: $footer-menu-height;
      left: 0;
      transition: left $navbar-transition;
    }
  }
  .wrapper {
    max-width: 55rem;
    width: 100%;
    margin: 0 auto;
    padding: 0.75em 0.85em;
  }
  p {
    margin: 0.4em 0 0.4em;
  }
  .link-tyc {
    display: block;
    padding: 0.25em 0;
  }
  button {
    border: none;
    border-radius: 0.25em;
    display: block;
    padding: 0.5em 0.75em;
    margin: 0 auto;
    min-width: 10em;
    font-size: 1.25em;
  }
  @include desktop {
    .content {
      font-size: 0.85em;
    }
    button {
      font-size: 0.9em;
    }
  }
}
body.cbp-spmenu-push-toright .floating-footer .content.fixed {
  left: $navbar-width;
}
</style>
