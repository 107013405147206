<template>
  <nav class="nav-profile">
    <div class="nav-bar">
      <HamburguerIcon @click="isMenuActive = !isMenuActive"/>
      <nuxt-link
        to="/"
        class="link-home">
        <img
          :src="logoUrl"
          alt="Home link">
      </nuxt-link>
    </div>
    <Menu :isMenuActive="isMenuActive" />
  </nav>
</template>
<script>
import Menu from '@/components/Menu.vue'
import HamburguerIcon from '@/components/HamburguerIcon.vue'
export default {
  name: 'NavProfile',
  components: {
    Menu,
    HamburguerIcon
  },
  data: function() {
    return {
      isMenuActive: false
    }
  },
  computed: {
    logoUrl() {
      if (this.$store.state.settings && this.$store.state.settings.logoUrl) {
        return this.$store.state.settings.logoUrl
      }
      return require('../assets/logo.png')
    }
  },
  watch: {
    $route() {
      this.isMenuActive = false
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/resources.sass';
.nav-profile {
  .nav-bar {
    margin: 0;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
  }
  .link-home {
    margin: 0 1.5rem;
    img {
      height: 3rem;
    }
  }
}
</style>
