<template>
  <div class="layout-profile">
    <UserNotifications/>
    <Popup/>
    <NavbarProfile />
    <nuxt/>
    <FooterDescription/>
    <FooterMenu/>
  </div>
</template>
<script>
import NavbarProfile from '@/components/NavbarProfile.vue'
import FooterDescription from '@/components/FooterDescription.vue'
import FooterMenu from '@/components/FooterMenu.vue'
import Popup from '@/components/Popup'
import UserNotifications from '@/components/UserNotifications'

export default {
  name: 'LayoutProfile',
  components: {
    NavbarProfile,
    FooterMenu,
    FooterDescription,
    Popup,
    UserNotifications
  },
  head() {
    return {
      link: this.$store.getters.headThemeResources,
      bodyAttrs: {
        class: this.bodyClass
      }
    }
  },
  computed: {
    bodyClass() {
      let classList = []
      if (this.$store.state.product) {
        classList.push('product-' + this.$store.state.product)
      }
      return classList.join(' ')
    }
  }
}
</script>
<style lang="scss">
@import '@/sass/resources.sass';
.layout-profile {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .button-hamburger {
    position: relative;
    background-color: transparent;
    border: none;
    appearance: none;
    width: 2.25rem;
    padding: 0;
    position: relative;
    z-index: 100;
    .icon-bar {
      display: block;
      width: 100%;
      height: 0.4rem;
      left: 0;
      margin: 0 0 0.3rem;
      border-radius: 0.25rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .profile-card {
    border-radius: 0.5rem;
    max-width: 25rem;
    width: 100%;
    padding: 2rem 1.5rem;
    margin: 1rem auto;
    position: relative;
    .styled-heading {
      font-weight: 700;
      &:before {
        content: ' ';
        width: 1rem;
        height: 3rem;
        display: block;
        position: absolute;
        left: -0.5rem;
        border-radius: 0.5rem;
        transform: translateY(-0.5rem);
      }
      small {
        font-size: 1rem;
        display: block;
      }
    }
    table {
      width: calc(100% + 3rem);
      text-align: center;
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      td {
        padding: 0.25rem 0.75rem;
      }
      th {
        opacity: 0.5;
        font-weight: 700;
      }
    }
  }
  .page-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }
  .button {
    display: block;
    padding: 0.5rem 0.5rem;
    font-size: 1.25rem;
    text-align: center;
    border-radius: 1.75rem;
    box-shadow: unset;
    width: 100%;
    appearance: none;
    &.loading {
      opacity: 0.5;
      cursor: progress !important;
      filter: saturate(0.75);
    }
  }
  .footerDescription {
    margin-top: auto;
  }
}
</style>
