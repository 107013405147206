export default function(context) {
  let axios = context.app.$axios
  return new Promise(async (resolve, reject) => {
    let vue = context.app

    /**
     * Handle Subscribe after Login
     */
    const handleSubscribeOnLogin = () => {
      if (vue.$cookies.get('pg_subscribe') && context.store.state.profile) {
        context.store.dispatch('handleSubscribe')
        vue.$cookies.remove('pg_subscribe')
        context.store.dispatch('log', {
          type: 'info',
          message: '[pg_subscribe] dispatched handleSubscribe'
        })
      }
    }

    /**
     * Fetch Home Gallery
     */
    const fetchHomeGallery = async () => {
      let response = await context.store.dispatch('backend/getGallery')
      if (response.status !== 200) {
        context.error({
          statusCode: 400,
          message: `Se produjo un error al cargar el sitio. Vuelve a cargar la página o intenta nuevamente más tarde.`,
          clientErrorDetails: `[fetchHomeGallery] ${response.statusText ||
            response.error}.`
        })
        return
      }
      context.store.commit('set', {
        homeSlides: response.data.data.result.tags
      })
      context.store.commit('set', {
        mainSlide: response.data.data.result.slides
      })
    }
    /**
     * Fetch categories
     */
    const fetchCategories = async () => {
      let response = await context.store.dispatch('backend/getCategories')
      if (response.status !== 200 && response.status !== 404) {
        context.error({
          statusCode: 400,
          message: `Se produjo un error al cargar el sitio. Vuelve a cargar la página o intenta nuevamente más tarde.`,
          clientErrorDetails: `[fetchcategories] ${response.statusText ||
            response.error}.`
        })
        return
      }
      context.store.commit('set', {
        categories: response.data.data
      })
    }
    /**
     * Fetch Medals
     */
    const fetchMedals = async () => {
      let response = await context.store.dispatch('backend/getMedals')
      if (response.status !== 200 && response.status !== 404) {
        context.error({
          statusCode: 400,
          message: `Se produjo un error al cargar el sitio. Vuelve a cargar la página o intenta nuevamente más tarde.`,
          clientErrorDetails: `[fetchMedals] ${response.statusText ||
            response.error}.`
        })
        return
      }
      context.store.commit('set', {
        medals: response.data.data
      })
    }

    const getToken = async payload => {
      const vue = payload.vue

      context.store.dispatch('log', {
        type: 'info',
        message:
          'pg_tkn: ' +
          vue.$cookies.get('pg_tkn') +
          ', pg_cv:' +
          vue.$cookies.get('pg_cv') +
          ', pg_cd:' +
          vue.$cookies.get('pg_cd')
      })

      if (vue.$cookies.get('pg_tkn')) {
        // If you already have a token use that token
        context.store.state.token = vue.$cookies.get('pg_tkn')
        context.store.state.codeVerifier = vue.$cookies.get('pg_cv')
        await context.store.dispatch('getUser', { vue })
        payload.fallback()
      } else if (!vue.$cookies.get('pg_tkn') && vue.$cookies.get('pg_cd')) {
        // Request Token from Backend
        try {
          let params = {
            code: vue.$cookies.get('pg_cd'),
            codeVerifier: vue.$cookies.get('pg_cv'),
            udi: vue.$cookies.get('pg_udi_tkn'),
            product: context.store.state.product
          }
          if (vue.$cookies.get('pg_udi_tkn')) {
            params.udi = vue.$cookies.get('pg_udi_tkn')
          }
          // Add Provider Param
          params = await context.store.dispatch('addProviderParam', {
            params
          })
          // Request Token
          let response = await axios.request({
            method: 'get',
            url: `${context.store.state.env.BACKEND_URL}/v1/users/token`,
            withCredentials: true,
            params
          })
          context.store.dispatch('log', {
            type: 'info',
            request: response
          })

          try {
            context.store.dispatch('log', {
              type: 'info',
              message:
                '[ Setttings Meta ] ' +
                JSON.stringify(response.data.data.settings['_meta'])
            })
          } catch (error) {}

          try {
            context.store.dispatch('log', {
              type: 'info',
              message:
                '[ Subscriptions ] ' +
                JSON.stringify(response.data.data.subscriptions)
            })
          } catch (error) {}

          try {
            context.store.dispatch('log', {
              type: 'info',
              message:
                '[ Profile ] ' + JSON.stringify(response.data.data.profile)
            })
          } catch (error) {}

          try {
            context.store.dispatch('log', {
              type: 'info',
              message:
                '[ tyc ] ' +
                JSON.stringify(response.data.data.settings.pages.TyC)
            })
          } catch (error) {}

          // Save token on Session
          vue.$cookies.set('pg_tkn', response.data.data.token, {
            path: '/'
          })

          // Commit token, paymentMethods and setting on states
          context.store.commit('set', {
            srIdTransaction: response.data.data.srIdTransaction
          })
          context.store.dispatch('setUserData', response.data.data)
          context.store.commit('set', {
            codeVerifier: vue.$cookies.get('pg_cv')
          })
          payload.fallback()
        } catch (error) {
          context.store.dispatch('log', {
            type: 'error',
            request: error
          })
          context.app.$cookies.set(
            'pg_load_errors',
            [
              {
                title:
                  '<i class="fa fa-exclamation-triangle"></i>  Error: No se pudo obtener el token',
                message: 'Intente Nuevamente mas tarde'
              }
            ],
            { path: '/' }
          )
          context.store.dispatch('removeCookies')
        }
      } else {
        payload.fallback()
      }
    }

    const auth = async (resolve, reject) => {
      const vue = context.app
      await getToken({
        vue: vue,
        route: context.route,
        redirect: context.redirect,
        fallback: async () => {
          // If there is no token
          if (!vue.store.state.token) {
            // Fetch Settings
            try {
              await context.store.dispatch('getSettings', {
                vue,
                route: context.route,
                callback: async () => {
                  if (
                    context.route.query.state &&
                    context.route.query.state == 'logged'
                  ) {
                    context.store.dispatch('signin', {
                      vue,
                      redirect: context.redirect,
                      redirectUri: context.store.getters.cleanQuery(
                        context.route.query,
                        ['state']
                      )
                    })
                  } else {
                    Promise.all([
                      fetchCategories(),
                      fetchHomeGallery(),
                      fetchMedals()
                    ]).then(values => {
                      resolve()
                    })
                  }
                },
                req: context.req
              })
            } catch (error) {
              context.store.dispatch('removeCookies')
              console.log(error)
              context.error({
                statusCode: 400,
                message: `Se produjo un error al cargar el sitio. Vuelve a cargar la página o intenta nuevamente más tarde.`,
                clientErrorDetails: `[getSettings] ${error}.`
              })
              resolve()
            }
            //if there is a ani on the session commit it to the store
            if (vue.$cookies.get('pg_ani')) {
              context.store.commit('set', { ani: vue.$cookies.get('pg_ani') })
            }
          } else {
            Promise.all([
              fetchCategories(),
              fetchHomeGallery(),
              fetchMedals()
            ]).then(values => {
              resolve()
            })
          }
          handleSubscribeOnLogin()
        }
      })
    }

    if (!context.store.state.settings) {
      auth(resolve, reject)
    } else if (context.route.name == 'index') {
      await fetchHomeGallery()
      resolve()
    } else {
      resolve()
    }
  })
}
