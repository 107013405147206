import products from '../../config/products.js'
export default function({ store, req, route, error }) {
  // Variables
  const environments = ['PROD', 'ST', 'DEV']
  const host = process.browser ? window.location.host : req.headers.host
  // Set Host
  store.commit('set', { host })
  // Find Host
  let foundEnv = false

  for (const productSlug in products) {
    if (products.hasOwnProperty(productSlug)) {
      const product = products[productSlug]
      const environment = environments.find(environment => {
        return product[environment + '_HOSTS'].find(hostItem => {
          return hostItem.host == host
        })
      })
      if (environment) {
        foundEnv = true
        const envConfig = require(`../../config/${productSlug.toLowerCase()}/${environment.toLowerCase()}.env.js`)
        store.commit('setEnvConfig', envConfig)
        store.commit('set', {
          providersHosts: product['PROVIDERS_HOSTS'],
          favicon: product['FAVICON_URL'],
          product: productSlug
        })
        store.dispatch('log', {
          type: 'info',
          message: `[setLiveEnv] You are on ${environment} ${productSlug}`
        })
        break
      }
    }
  }
  if (!foundEnv) {
    store.dispatch('log', {
      type: 'error',
      message: `[setLiveEnv] No env found for ${host}`
    })
    error({
      statusCode: 500,
      message: `[setLiveEnv] No env found for ${host}`
    })
  }
}
