<template>
  <div id="loading">
    <i
      id="spinner"
      class="fa fa-spinner fa-spin large" />
  </div>
</template>

<script>
export default {
  name: 'Loading'
}
</script>

<style lang="scss">
@import '@/sass/resources.sass';
#loading {
  margin-top: 0;
  height: 400px;
  min-height: 100vh;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
}
#spinner {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
  font-size: 50px;
}
</style>
