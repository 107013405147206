export default {
  PlanetaGuru: {
    FAVICON_URL: 'https://storage.googleapis.com/planeta-guru-assets-bucket/images/favicon/favicon-pgj.ico',
    DEV_HOSTS: [
      { host: 'planeta.guru.test:3000' },
      { host: 'perso.planeta.guru.test:3000' }
    ],
    PROD_HOSTS: [
      {
        host: 'planeta.guru',
        googleTagManager: {
          id: 'GTM-KTTMGR9',
          layer: 'dataLayer',
          pageTracking: true
        }
      },
      { host: 'perso.planeta.guru' },
      { host: 'perso.planetaguru.net' },
      { host: 'www.planetagame.net' }
    ],
    /*
    ** STAGING_HOSTS
    */
    ST_HOSTS: [
      { host: 'staging.planeta.guru' },
      { host: 'staging.perso.planeta.guru' },
      { host: 'staging.test' }
    ],

    /*
    ** PROVIDERS_HOSTS
    ** Si el host coincide, agrega el parametro `provider` a todos los requests
    ** Si se define el key `protocol`, este se usará para contruir el href (redirects)
    */
    PROVIDERS_HOSTS: [
      {
        host: 'perso.planeta.guru',
        provider: 'ar.personal.',
        genericName: 'Personal',
        other_keys: ['ar.personal.cdag'],
        protocol: 'http',
        googleTagManager: {
          id: 'GTM-5QJFXV5',
          layer: 'dataLayer',
          pageTracking: true
        }
      },
      {
        host: 'perso.planetaguru.net',
        provider: 'ar.personal.',
        genericName: 'Personal',
        other_keys: ['ar.personal.cdag'],
        protocol: 'http',
        googleTagManager: {
          id: 'GTM-5QJFXV5',
          layer: 'dataLayer',
          pageTracking: true
        }
      },
      {
        host: 'staging.perso.planeta.guru',
        provider: 'ar.personal.',
        genericName: 'Personal',
        other_keys: ['ar.personal.cdag'],
        protocol: 'http',
        googleTagManager: {
          id: 'GTM-5QJFXV5',
          layer: 'dataLayer',
          pageTracking: false
        }
      },
      {
        host: 'perso.planeta.guru.test:3000',
        provider: 'ar.personal.',
        genericName: 'Personal',
        protocol: 'http',
        googleTagManager: {
          id: 'GTM-5QJFXV5',
          layer: 'dataLayer',
          pageTracking: true
        }
      },
      {
        host: 'planeta.guru',
        googleTagManager: {
          id: 'GTM-KTTMGR9',
          layer: 'dataLayer',
          pageTracking: true
        }
      }
    ]
  },
  html5: {
    FAVICON_URL: 'https://storage.googleapis.com/planeta-guru-assets-bucket/images/favicon/favicon-pgj.ico',
    DEV_HOSTS: [{ host: 'online.planeta.guru.test:3000' }],
    PROD_HOSTS: [{ host: 'online.planeta.guru' }],
    /*
    ** STAGING_HOSTS
    */
    ST_HOSTS: [
      { host: 'staging.online.planeta.guru' },
      { host: 'staging.online.planeta.guru.test:3000' }
    ],
    /*
    ** PROVIDERS_HOSTS
    ** Si el host coincide, agrega el parametro `provider` a todos los requests
    ** Si se define el key `protocol`, este se usará para contruir el href (redirects)
    */
    PROVIDERS_HOSTS: []
  },
  PlanetaGuruNet: {
    FAVICON_URL: 'https://storage.googleapis.com/planeta-guru-assets-bucket/images/favicon/favicon-pgj.ico',
    DEV_HOSTS: [{ host: 'planetagurunet.test:3000' }],
    PROD_HOSTS: [
      {
        host: 'planetaguru.net',
        googleTagManager: {
          id: 'GTM-KTTMGR9',
          layer: 'dataLayer',
          pageTracking: true
        }
      }
    ],
    /*
    ** STAGING_HOSTS
    */
    ST_HOSTS: [
      { host: 'staging.planetaguru.net' },
      { host: 'staging.planetagurunet.test:3000' }
    ],
    /*
    ** PROVIDERS_HOSTS
    ** Si el host coincide, agrega el parametro `provider` a todos los requests
    ** Si se define el key `protocol`, este se usará para contruir el href (redirects)
    */
    PROVIDERS_HOSTS: []
  },
  clarovr: {
    FAVICON_URL: 'https://storage.googleapis.com/planeta-guru-assets-bucket/images/favicon/favicon-CVR%202.ico',
    DEV_HOSTS: [{ host: '192.168.0.107:3000' }],
    PROD_HOSTS: [{ host: 'www.clarovr.com' }],
    /*
    ** STAGING_HOSTS
    */
    ST_HOSTS: [
      { host: 'staging.clarovr.test:3000' },
      {
        host: 'staging.clarovr.com',
        protocol: 'http'
      },
      { host: 'staging-new.clarovr.com' },
      { host: 'staging.clarovr.com:3000' }
    ],
    /*
    ** PROVIDERS_HOSTS
    ** Si el host coincide, agrega el parametro `provider` a todos los requests
    ** Si se define el key `protocol`, este se usará para contruir el href (redirects)
    */
    PROVIDERS_HOSTS: [
      {
        host: 'www.clarovr.com',
        googleTagManager: {
          id: 'GTM-PKTKXK9',
          layer: 'dataLayer',
          pageTracking: true
        }
      },
      {
        host: '192.168.0.107:3000',
        protocol: 'http',
        googleTagManager: {
          id: 'GTM-PKTKXK9',
          layer: 'dataLayer',
          pageTracking: true
        }
      }
    ]
  }
}
