<template>
  <div
    v-if="popup && popup.name"
    class="main-popup"
    @click.self="$store.commit('popup', null)">
    <div
      v-if="popup.name == 'login'"
      class="card popup-login">
      <h3>{{ $store.getters.messages('loginPopupTitle') }}</h3>
      <p>{{ $store.getters.messages('loginPopupText') }}</p>
      <div class="buttons">
        <button
          class="btn cancel"
          @click.self="$store.commit('popup', null)">{{ $store.getters.messages('cancel') }}
        </button>
        <button
          v-if="popup.gameSlug"
          class="btn login"
          @click="$store.dispatch('handleGetContentFile', {slug: popup.gameSlug})">
          {{ $store.getters.messages('login') }}
        </button>
        <button
          v-else
          class="btn login"
          @click="$store.dispatch('signin', {vue: $router.app.$root})">{{ $store.getters.messages('login') }}
        </button>
      </div>
    </div>
    <div
      v-if="popup.name == 'loginScore'"
      class="card popup-login">
      <h3>{{ $store.getters.messages('scorePopupTitle') }}</h3>
      <p>{{ $store.getters.messages('scorePopupText') }}</p>
      <div class="buttons">
        <button
          class="btn cancel"
          @click.self="$store.commit('popup', null)">{{ $store.getters.messages('cancel') }}
        </button>
        <button
          v-if="popup.gameSlug"
          class="btn login"
          @click="$store.dispatch('handleGetContentFile', {slug: popup.gameSlug})">
          {{ $store.getters.messages('login') }}
        </button>
        <button
          v-else
          class="btn login"
          @click="$store.dispatch('signin', {vue: $router.app.$root})">{{ $store.getters.messages('login') }}
        </button>
      </div>
    </div>
    <div
      v-if="popup.name == 'unsubscribe'"
      class="card popup-login">
      <h3>{{ $store.getters.messages('cancelSubscription') }}</h3>
      <p>{{ $store.getters.messages('cancelSubscriptionMessage') }}</p>
      <div class="buttons">
        <button
          class="btn cancel"
          @click.self="$store.commit('popup', null)">{{ $store.getters.messages('no') }}
        </button>
        <button
          class="btn"
          @click.self="unsubscribe">{{ $store.getters.messages('yes') }}
        </button>
      </div>
    </div>
    <div
      v-if="popup.name == 'subscribeReminder'"
      class="card popup-subscribe-reminder">
      <h3>{{ $store.getters.messages('subscribe').toUpperCase() }}</h3>
      <p
        v-if="subscribeReminder.content"
        v-html="subscribeReminder.content"/>
      <div class="buttons">
        <button
          class="btn"
          @click="subscribe">{{ $store.getters.messages('subscribe').toUpperCase() }}
        </button>
      </div>
      <p
        v-if="subscribeReminder.terms"
        class="terms"
        v-html="subscribeReminder.terms"/>
    </div>
    <div
      v-if="popup.name === 'subscribeReminderClaroVR'"
      class="card-clarovr popup-subscribe-reminder">
      <h3>{{ subscribeReminderClaroVR.title }}</h3>
      <p
        v-if="subscribeReminderClaroVR.content"
        v-html="subscribeReminderClaroVR.content"/>
      <iframe
        :src="linkSuscription"
        allowfullscreen="true"
        scrolling="no"
        frameborder="0"
        width="100%"
        height="45">Cargando
      </iframe>
      <p
        v-if="subscribeReminderClaroVR.terms"
        class="terms-clarovr"
        v-html="subscribeReminderClaroVR.terms"/>
    </div>
    <div
      v-if="popup.name === 'directAccess'"
      class="card popup-directAccess">
      <div
        class="close">
        <img
          class="right btn-close"
          src="../static/pgj-close-popup.svg"
          @click.self="$store.commit('popup', null)">
      </div>
      <h3
        class="title">Bienvenido a Planeta Guru</h3>
      <p
        class="text">
        Para añadir esta aplicacion web a la pantalla de inicio abre las opciones y pulsa <b>Añadir a pantalla de
        inicio</b>
      </p>
      <span
        class="terms">
        El menú se puede acceder pulsando el botón táctil en caso de tenerlo o bien el icono de la parte superior derecha de la pantalla
        <img
          class="menu-img"
          src="../static/pgj-menu-example.png">
      </span>
    </div>
    <div
      v-if="popup.name == 'score'"
      class="card popup-score"
      style="padding-top: 3rem;">
      <img
        :src="popup.imagen"
        style="  border-radius: 50%;height: 80px;width: 80px;align-self: center;position: absolute;top: -35px;">
      <div class="card-block">
        <table style="width: 100%">
          <thead style="text-align:center">
            <th colspan="2">
              <h3 class="text-center">Score</h3>
            </th>
          </thead>
          <tbody
            v-for="(item, index) in popup.data "
            :key="index"
            style="font-size: 1.2rem;padding:0;text-align: left;">
            <tr style="border-bottom: 1px solid #3f3f3f;">
              <td>{{ item.nickname }}</td>
              <td style="text-align: right; color: red;">{{ item.score }} pts</td>
            </tr>
          </tbody>
        </table>
        <br>
        <div class="buttons">
          <button
            class="btn score"
            @click.self="$store.commit('popup', null)">{{ $store.getters.messages('continue') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PopUp',
  computed: {
    popup() {
      return this.$store.state.popup
    },
    linkSuscription() {
      let protoco = location.protocol !== 'https:' ? 'http' : 'https'
      let linkSuscription =
        protoco +
        '://' +
        this.$store.state.settings.subscribeReminderClaroVR.link

      let params = this.$store.app.$cookies.get('pg_params')

      if (params) {
        let utm_source = params.utm_source
        let utm_medium = params.utm_medium
        let utm_campaign = params.utm_campaign

        let q = linkSuscription.indexOf('?') >= 0

        if (utm_source)
          linkSuscription += (q ? '&' : '?') + 'utm_source=' + utm_source

        q = linkSuscription.indexOf('?') >= 0
        if (utm_medium)
          linkSuscription += (q ? '&' : '?') + 'utm_medium=' + utm_medium

        q = linkSuscription.indexOf('?') >= 0
        if (utm_medium)
          linkSuscription += (q ? '&' : '?') + 'utm_campaign=' + utm_campaign
      }
      return linkSuscription
    },
    subscribeReminderClaroVR() {
      return this.$store.state.settings.subscribeReminderClaroVR || {}
    },
    subscribeReminder() {
      return this.$store.state.settings.subscribeReminder || {}
    }
  },
  watch: {
    $store(oldData, newData) {
      console.log(oldData, newData)
    }
  },
  methods: {
    unsubscribe() {
      this.$store.commit('set', { loading: true })
      this.$store.dispatch('pgserver/goToSubscriptions', {
        unsubscribe: true
      })
    },
    subscribe() {
      this.$store.commit('popup', null)
      this.$store.dispatch('handleSubscribe')
    }
  }
}
</script>
<style lang="sass">
@import '@/sass/resources.sass'
.main-popup
  position: fixed
  display: flex
  align-content: center
  align-items: center
  justify-content: center
  width: 100vw
  height: 100vh
  top: 0
  left: 0
  z-index: 10001

  iframe
    display: block

  h3
    font-weight: 700
    margin: 0em 0 0.4em

  input
    margin: 0.75em 0 0.5em
    line-height: 2em
    // border-radius: 0.25em
    display: block
    width: 100%
    padding: 0 0.5em

  select
    margin-top: 0.5em
    width: 100%

  .card
    width: 100%
    max-width: 350px
    padding: 2em 2em

  .card-clarovr
    background-color: #2B3F51
    width: 100%
    max-width: 350px
    padding: 2em 2em

    .card-score
    width: 100%
    max-width: 350px
    padding: 1em 1em

    p
      color: #FFFFFF
      text-align: center

    h3
      color: #FFFFFF

  .btn
    z-index: 2
    margin-top: 0.5rem

    &.disabled
      pointer-events: none
      opacity: 0.5

.popup-login
  .buttons
    display: flex
    margin: 0 -0.25rem

    .btn
      flex: 1
      margin: 0 0.25rem

.popup-score
  margin-top: 30px
  padding-top: 30px


  .buttons
    display: flex
    margin: 0 -0.25rem

    .btn
      flex: 1
      margin: 0 0.25rem


.popup-subscribe-reminder
  .btn
    width: 100%
    margin: 0 0 1rem

  .terms
    font-size: 0.75em
    line-height: 1.25em
    opacity: 0.5
    margin: 0 0 0.5em

  .terms-clarovr
    font-size: 0.75em
    line-height: 1.25em
    margin: 0 0 0.5em

.popup-directAccess
  border-radius: 1.25rem !important
  background-color: #0d2036 !important
  color: #fff !important
  text-align: center
  font-size: 1rem !important

  .terms
    font-size: 0.8rem !important
    font-weight: 500

    .menu-img
      margin-left: 3px

  .title
    color: #63b6bf !important
    font-size: 1.75rem !important

  .btn-close
    float: right
    min-width: 50px
    height: 50px
</style>
