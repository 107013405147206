export default {
  hasValidSuscription(state) {
    if (!state.subscriptions) {
      return false
    }
    return state.validSubscriptionStatus.includes(
      state.subscriptions.mainSubscriptionStatus
    )
  },
  availableDownloads(state) {
    let downloads = 0
    if (state.profile) {
      if (
        state.profile.funds.GUR &&
        state.gamePrice &&
        state.profile.funds.GUR >= state.gamePrice
      ) {
        downloads += Math.floor(state.profile.funds.GUR / state.gamePrice)
      }
      if (state.profile.trial && state.profile.trial.available) {
        downloads += state.profile.trial.available
      }
    }
    return downloads
  },
  unlimitedDownloads(state) {
    if (state.profile && state.profile.funds.GUR > state.unlimitedThreshold) {
      return true
    } else {
      return false
    }
  },
  messages: state => message => {
    if (
      state.settings &&
      state.settings.messages &&
      state.settings.messages[message]
    ) {
      return state.settings.messages[message]
    }
    return state.defaultSettings.messages[message]
  },
  cleanQuery: state => (query, exclude) => {
    let newUrl = state.hrefShort
    if (query) {
      let queryCount = 0
      Object.keys(query).forEach(function(key) {
        if (
          key &&
          (!exclude ||
            (exclude && !exclude.find(excludeKey => key == excludeKey)))
        ) {
          if (queryCount > 0) {
            newUrl += `&${key}=${query[key]}`
          } else {
            newUrl += `?${key}=${query[key]}`
          }
          queryCount++
        }
      })
    }
    return newUrl
  },
  categoriesFromTags: state => tags => {
    return tags.filter((tag, index) => {
      if (tag.tag_types) {
        return tag.tag_types.includes('genre')
      } else if (tag.tag_type) {
        return tag.tag_type === 'genre'
      } else {
        return false
      }
    })
  },
  providerHost: state => {
    try {
      return state.providersHosts.find(function(provider) {
        return provider.host == state.host
      })
    } catch (error) {
      return null
    }
  },
  paymentMethodsAsArray: state => {
    let paymentMethods = []
    // Exit if there are no paymentMethods
    if (!state.paymentMethods) {
      return null
    }
    // Convert paymentMethods to array
    if (
      Object.prototype.toString.call(state.paymentMethods) === '[object Array]'
    ) {
      paymentMethods = state.paymentMethods
    } else {
      if (state.paymentMethods.subscription) {
        paymentMethods = paymentMethods.concat(
          state.paymentMethods.subscription
        )
      }
      if (state.paymentMethods.ondemand) {
        paymentMethods = paymentMethods.concat(state.paymentMethods.ondemand)
      }
    }
    return paymentMethods
  },
  hasRug: (state, getters) => {
    if (
      state.settings &&
      state.paymentMethods &&
      ((!state.profile &&
        state.settings.anonymousSubscription &&
        getters.defaultRugPaymentMethod) ||
        (state.profile && getters.defaultRugPaymentMethod) ||
        (state.profile && state.selectedRugPaymentMethod))
    ) {
      return true
    }
    return false
  },
  defaultRugPaymentMethod: (state, getters) => {
    if (!getters.paymentMethodsAsArray) {
      return null
    }
    return getters.paymentMethodsAsArray.find(method => {
      return method.frontendAmmo
    })
  },
  headThemeResources(state) {
    let resources = []
    let theme = 'default'
    let staticPathBase =
      !state.env.NODE_ENV.includes('development') && state.staticPathBase
        ? state.staticPathBase
        : '/'

    if (state.settings && state.settings.theme) {
      theme = state.settings.theme
    }

    if (state.settings && state.settings.extraStyles) {
      resources = state.settings.extraStyles.map(style => {
        return { rel: 'stylesheet', href: style, title: 'custom' }
      })
    }

    if (theme !== 'none') {
      resources.push({
        rel: 'stylesheet',
        href: `${staticPathBase}${theme}.css`,
        title: 'custom'
      })
    }
    return resources
  }
}
