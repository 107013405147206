<template>
  <nuxt-link
    v-if="link.to"
    :to="link.to"
    class="menu-link">
    {{ link.title }}
    <i
      :class="icon"
      class="fas"/>
  </nuxt-link>
  <a
    v-else-if="link.click"
    class="menu-link"
    @click.prevent="link.click">
    {{ link.title }}
    <i
      :class="icon"
      class="fas"/>
  </a>
  <a
    v-else-if="link.links"
    class="menu-link"
    @click.prevent="$emit('click')">
    {{ link.title }}
    <i
      :class="icon"
      class="fas"/>
  </a>
  <a
    v-else
    :href="link.href"
    class="menu-link">
    {{ link.title }}
    <i
      :class="icon"
      class="fas"/>
  </a>
</template>
<script>
export default {
  name: 'MenuLink',
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      return this.link.links ? 'fa-angle-down' : 'fa-angle-right'
    }
  }
}
</script>
<style lang="scss">
.menu-link {
  width: 100%;
  display: block;
  padding: 0.5rem;
  margin-bottom: -1px;
  border-width: 0;
  border-width: 0;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  text-align: right;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  .fas {
    font-size: 1.25rem;
    margin-left: 0.25rem;
  }
}
.menu-main .menu-link + ul {
  margin-bottom: 0;
}
</style>
