<template>
  <div
    v-if="$store.state.settings && showPopup"
    class="welcome-popup"
    @click.self="close">
    <div
      class="card popup-login">
      <button
        class="button-close"
        @click="close">
        <i class="fa fa-times"/>
      </button>
      <img
        v-if="popup.image"
        :src="popup.image"
        :alt="popup.title">
      <h3 v-if="popup.title">{{ popup.title }}</h3>
      <div
        v-if="popup.content"
        class="content"
        v-html="popup.content"/>
      <div
        v-if="popup.ctaName && popup.ctaLink"
        class="buttons">
        <button
          class="btn"
          @click="handleCta">{{ popup.ctaName }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'WelcomePopUp',
  data() {
    return {
      showPopup: false
    }
  },
  computed: {
    popup() {
      return this.$store.state.settings.welcomePopup
    }
  },
  mounted() {
    if (
      this.$route.name !== 'download-slug' &&
      !this.$cookies.get('pg_wcpp') &&
      this.popup &&
      (this.popup.content || this.popup.image)
    ) {
      this.showPopup = true
    }
  },
  methods: {
    close() {
      this.showPopup = false
      this.$cookies.set('pg_wcpp', true, { path: '/' })
    },
    handleCta() {
      this.close()
      if (process.browser) {
        window.location.href = this.popup.ctaLink
      }
    }
  }
}
</script>
<style lang="sass">
@import '@/sass/resources.sass';
.welcome-popup
    position: fixed;
    display: flex;
    align-content: center
    align-items: center
    justify-content: center
    width: 100vw;
    height: 100vh;
    top: 0
    left: 0
    z-index: 4
    img
        display: block
        width: 100%
        height: auto
        margin: 0 0 1.25em
    h3
      font-weight: 700
      margin: 0em 0 0.5em
      line-height: 1.1em
    .content
        margin-bottom: 1em
        line-height: 1.5em
    .card
      width: 100%
      max-height: 90vh;
      +overflow
      max-width: 350px;
      padding: 2em 2em
    .btn
      z-index: 2
      margin-top: 0.5rem
      &.disabled
        pointer-events: none;
        opacity: 0.5
    button.button-close
        display: block
        width: auto
        margin: -1.75em -1.75em 0 auto;
        padding: 0.5em
        font-size: 1em
        border: none;
        background-color: transparent;
    +mobile
        button.button-close
            margin: -1em -1em 0 auto;
        .card
            margin-bottom: 4em
            padding: 1.25em 1.5em
</style>
