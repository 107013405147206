import defaultSettings from '../mockups/defaultSettings'
export default () => ({
  favicon: null,
  env: null, //JSON.parse(process.env.config),
  host: null,
  href: null,
  hrefShort: null,
  isSubscriptionMethodsVisible: false,
  isMobileScreen: true,
  token: null,
  settings: null,
  srIdTransaction: null,
  defaultSettings: defaultSettings,
  extraScripts: null,
  paymentMethods: null,
  subscriptions: null,
  profile: null,
  ani: null,
  product: null,
  categories: null,
  medals: null,
  language: [],
  gameContent: null,
  uCode: null,
  loading: false,
  mainSlide: null,
  homeSlides: null,
  gamePrice: JSON.parse(process.env.globalConfigs).GAME_PRICE,
  unlimitedThreshold: JSON.parse(process.env.globalConfigs).UNLIMITED_THRESHOLD,
  resetSearchQuery: false,
  paginationLimit: JSON.parse(process.env.globalConfigs).PAGINATION_LIMIT,
  staticPathBase: JSON.parse(process.env.globalConfigs).STATIC_PATH_BASE,
  timeout: JSON.parse(process.env.globalConfigs).TIMEOUT,
  popup: {
    name: null,
    gameSlug: null
  },
  ssReq: null,
  codeChallenge: null,
  CodeVerifier: null,
  providersHosts: JSON.parse(process.env.globalConfigs).PROVIDERS_HOSTS,
  isMenuActive: false,
  isRugVisible: false,
  selectedRugPaymentMethod: null,
  params: null,
  debugConfig: {
    notify: 'default'
  },
  isFullScreenActive: false,
  reqIP: null,
  reqProvider: null,
  reqMethod: null,
  isSubscribeReminderRunning: false,
  validSubscriptionStatus: JSON.parse(process.env.globalConfigs)
    .VALID_SUBSCRIPTION_STATUS
})
