export default {
  signup(state, vue) {
    const signupUrl =
      `${state.env.OAUTH_URL}/auth/authorize/signup` +
      '?response_type=code' +
      `&client_id=${state.env.CLIENT_ID}` +
      `&redirect_uri=${encodeURIComponent(window.location.href)}` +
      `&product_id=${state.env.PRODUCT_ID}` +
      `&code_challenge=${vue.$cookies.get('pg_cc')}` +
      '&code_challenge_method=S256' +
      '&state=login' +
      '&locale=es_AR'
    window.location.href = signupUrl
  },
  logOut(state, vue) {
    vue.$nuxt.$loading.start()
    const requestFallback = response => {
      vue.$store.dispatch('log', {
        type: 'info',
        request: response
      })
      // Destroy Session
      vue.$store.dispatch('removeCookies', { vue })
      vue.$store.commit('set', { isMenuActive: false })
      vue.$nuxt.$loading.finish()

      // Go to homepage and update
      window.location = window.location.origin + process.env.baseUrl
    }
    const requestCatch = error => {
      vue.$nuxt.$loading.fail()
      vue.$store.dispatch('log', {
        type: 'error',
        request: error
      })
      if (error.status === 401 || error.status === 400) {
        payload.vue.$store.dispatch('removeCookies', {
          vue: payload.vue
        })
      }
    }
    vue.$http
      .post(`${vue.$store.state.env.OAUTH_URL}/auth/token/slo`, '', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json, text/plain, */*'
        },
        params: {
          'content-type': 'application/x-www-form-urlencoded',
          client_id: vue.$store.state.env.CLIENT_ID,
          access_token: vue.$cookies.get('pg_tkn').accessToken
        }
      })
      .then(requestFallback, requestCatch)
  },
  toggleSubscriptionMethods(state) {
    state.isSubscriptionMethodsVisible = !state.isSubscriptionMethodsVisible
  },
  set(state, data) {
    Object.keys(data).forEach(key => {
      state[key] = data[key]
    })
  },
  setEnvConfig(state, config) {
    state.env = config
  },
  popup(state, payload) {
    if (payload) {
      state.popup = payload
      console.debug('[commit popup]', state.popup)
    } else {
      state.popup = {
        name: null
      }
    }
  },
  setDebugConfig(state, payload) {
    Object.keys(payload).forEach(key => {
      state.debugConfig[key] = payload[key]
    })
  }
}
